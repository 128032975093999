/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .paymentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 82px;
    padding: $padding-mobile;
    color: theme(color-primary);
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
    border-radius: 2px;
  }

  .fieldsContainer {
    padding: $padding-mobile $padding-mobile 0;
  }

  .creditCardForm {
    background-color: theme(panel-subpanel-background-color);
  }

  .paymentTitle {
    display: block;
    min-height: 21px;
    margin: 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-subtitle);
    line-height: 1.17;
  }

  .totalPrice {
    font-size: theme(font-size-subtitle);

    .totalPriceSub {
      font-size: 24px;
    }
  }

  .textEntry {
    font-size: theme(font-size-text);

    p {
      font-weight: theme(font-weight-lighter);
      line-height: 1.29;
    }
  }

  .inputContainer {
    width: 100%;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 18px;

    .inputContainerTitle {
      margin-bottom: 10px;
    }

    label {
      display: block;
      padding: 5px 0;
      font-size: theme(font-size-small-text);
    }

    select {
      height: 40px;
      padding: 0 15px;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-small-text);
      font-family: theme(font-primary);
      background-color: theme(panel-background-color);
      border: solid 1px theme(input-field-border-color);
      border-radius: 2px;
      outline: none;
    }

    input {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 15px;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-small-text);
      font-family: theme(font-primary);
      background-color: theme(panel-background-color);
      border: solid 1px theme(input-field-border-color);
      border-radius: 2px;
      outline: none;
      box-shadow: theme(input-field-inner-box-shadow);
    }
  }

  .checkbox {
    & + div {
      margin-left: 0;
    }
  }

  .holderNameInput {
    label {
      padding: 0 10px 0 0;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .paymentContainer {
      padding: $padding-desktop;
    }

    .fieldsContainer {
      padding: $padding-desktop $padding-desktop 10px;
    }

    .checkbox {
      & + div {
        margin-left: 23px;
      }
    }

    .inputContainer {
      label {
        flex-shrink: 0;
        width: 120px;
        padding-right: 20px;
      }

      input {
        flex: 1 1 190px;
      }
    }

    .inputAndLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
  }

  .hasError {
    label {
      margin-bottom: 0;
      color: theme(color-error);
    }

    input {
      margin-bottom: 0;
      border-color: theme(color-error);
    }
  }

  .required {
    &::after {
      color: theme(color-error);
      content: ' *';
    }
  }

  // a workaround for a flexbug in IE11
  @media all and (-ms-high-contrast: none) {
    .paymentContainer > :global(#paymentInfo) {
      max-width: 100%;
    }
  }
}
