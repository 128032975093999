@import 'src/styles/base';

@include themify($themes) {
  .aside {
    margin-top: 10px;
    padding: 20px;
    background-color: theme(panel-background-color);
    border-radius: 2px;

    &:first-child {
      margin-top: 0;
    }
  }

  .asideTitle {
    margin: 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    line-height: 1;
    text-transform: uppercase;
  }
}
