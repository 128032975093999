@import 'src/styles/base';

@include themify($themes) {
  .root {
    margin: -4px 0;
  }

  .title {
    margin-bottom: 1em;
    color: theme(color-primary);
    font-size: 20px;
    line-height: 1.1;
  }

  .links {
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;
    text-align: center;
  }

  .link {
    display: inline-block;
    color: theme(color-link);
    vertical-align: top;
    cursor: pointer;

    &:hover {
      color: theme(color-link-hover);
    }
  }
}
