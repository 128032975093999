@import 'src/styles/base';

@include themify($themes) {
  $ig-screen-sm-min: 768px !default;
  $ig-screen-xsm-min: 480px !default;
  $ig-white: #fff !default;
  $ig-black: #000 !default;
  $ig-blue: #337ab7 !default;
  $ig-background-black: rgba(0, 0, 0, 0.4) !default;
  $ig-transparent: rgba(0, 0, 0, 0) !default;
  $ig-shadow: 0 2px 2px lighten($ig-black, 10%);

  :global {
    .image-gallery {
      transform: translateZ(0);
      user-select: none;
      -webkit-tap-highlight-color: $ig-transparent;

      &.fullscreen-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background: $ig-black;

        .image-gallery-content {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .image-gallery-content {
      position: relative;
      top: 0;
      line-height: 0;

      &.fullscreen {
        background: $ig-black;

        .image-gallery-slide {
          background: $ig-black;
        }
      }
    }

    .image-gallery-slide-wrapper {
      position: relative;

      &.left,
      &.right {
        display: inline-block;
        width: calc(100% - 113px); // 100px + 8px for border + 5px for padding

        @media (max-width: $ig-screen-sm-min) {
          width: calc(100% - 84px); // 75px + 6px for border + 3px for padding
        }
      }
    }

    .image-gallery-slides {
      position: relative;
      height: 363px;
      overflow: hidden;
      line-height: 0;
      white-space: nowrap;

      @media (max-width: $ig-screen-sm-min) {
        height: 0;
        min-height: 210px;
        padding: 0 0 47%;
      }
    }

    .image-gallery-slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $ig-white;

      &.center {
        position: relative;
      }

      img {
        position: absolute;
        top: 0;
        width: 100%;
        min-height: 210px;
      }

      .image-gallery-description {
        position: absolute;
        bottom: 70px;
        left: 0;
        padding: 10px 20px;
        color: $ig-white;
        line-height: 1;
        white-space: normal;
        background: $ig-background-black;

        @media (max-width: $ig-screen-sm-min) {
          bottom: 45px;
          padding: 8px 15px;
          font-size: 0.8em;
        }
      }
    }

    .image-gallery-bullets {
      position: absolute;
      right: 0;
      bottom: 20px;
      left: 0;
      z-index: 4;
      width: 80%;
      margin: 0 auto;

      .image-gallery-bullets-container {
        margin: 0;
        padding: 0;
        text-align: center;
      }

      .image-gallery-bullet {
        display: inline-block;
        margin: 0 5px;
        padding: 5px;
        background-color: transparent;
        border: 1px solid $ig-white;
        border-radius: 50%;
        outline: none;
        box-shadow: 0 1px 0 lighten($ig-black, 10%);
        cursor: pointer;
        appearance: none;

        @media (max-width: $ig-screen-sm-min) {
          margin: 0 3px;
          padding: 3px;
        }

        @media (max-width: $ig-screen-xsm-min) {
          padding: 2.7px;
        }

        &.active {
          background: $ig-white;
        }
      }
    }

    .image-gallery-thumbnails-wrapper {
      position: relative;

      &.thumbnails-swipe-horizontal {
        touch-action: pan-y;
      }

      &.thumbnails-swipe-vertical {
        touch-action: pan-x;
      }

      &.thumbnails-wrapper-rtl {
        direction: rtl;
      }
      &.left,
      &.right {
        display: inline-block;
        vertical-align: top;
        width: 100px;

        @media (max-width: $ig-screen-sm-min) {
          width: 81px; // 75px + 6px for border
        }

        .image-gallery-thumbnails {
          height: 100%;
          width: 100%;
          left: 0;
          padding: 0;
          position: absolute;
          top: 0;

          .image-gallery-thumbnail {
            display: block;
            margin-right: 0;
            padding: 0;

            + .image-gallery-thumbnail {
              margin-left: 0;
              margin-top: 2px;
            }

          }

        }
      }

      &.left,
      &.right {
        margin: 0 5px;

        @media (max-width: $ig-screen-sm-min) {
          margin: 0 3px;
        }
      }
    }

    .image-gallery-thumbnails {
      overflow: hidden;
      padding: 5px 0;
      background: #000;

      @media (max-width: $ig-screen-sm-min) {
        padding: 3px 0;
      }

      .image-gallery-thumbnails-container {
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
      }

    }

    .image-gallery-thumbnail {
      display: inline-block;
      border: 4px solid transparent;
      transition: border .3s ease-out;
      width: 80px;
      margin: 10px 10px;
      background: transparent;
      padding: 0;

      @media (max-width: $ig-screen-sm-min) {
        border: 3px solid transparent;
        width: 81px;
      }

      + .image-gallery-thumbnail {
        margin-left: 2px;
      }

      .image-gallery-thumbnail-inner {
        display: block;
        position: relative;
      }

      .image-gallery-thumbnail-image {
        vertical-align: middle;
        width: 100%;
        line-height: 0;
      }

      &.active {
        opacity: 0.8;
      }
    }

    .image-gallery-thumbnail-label {
      box-sizing: border-box;
      color: white;
      font-size: 1em;
      left: 0;
      line-height: 1em;
      padding: 5%;
      position: absolute;
      top: 50%;
      text-shadow: $ig-shadow;
      transform: translateY(-50%);
      white-space: normal;
      width: 100%;

      @media(max-width: $ig-screen-sm-min) {
        font-size: .8em;
        line-height: .8em;
      }
    }

    .image-gallery-index {
      background: $ig-background-black;
      color: $ig-white;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 4;

      @media(max-width: $ig-screen-sm-min) {
        font-size: .8em;
        padding: 5px 10px;
      }
    }
  }

  .galleryLeft,
  .galleryRight {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 68px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .galleryRight {
    right: 0;
    left: auto;
  }

  .arrow {
    position: absolute;
    top: 50%;
    left: 0;
    margin: 0 25px;
    color: #fff;
    font-size: 25px;
    transform: translateY(-50%);
    transition: opacity 0.3s ease-in-out;
  }
}
