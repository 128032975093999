@import 'src/styles/base';

@include themify($themes) {
  .link {
    float: right;
    margin-left: 8px;
    color: theme(color-link);
    font-weight: theme(font-weight-light);
    font-size: 14px;
    font-family: theme(font-primary);
    text-transform: none;
    cursor: pointer;

    &:hover {
      color: theme(color-link-hover);
    }
  }

  .icon {
    margin-left: 5px;
  }
}
