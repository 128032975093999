@import 'src/styles/base';

@include themify($themes) {
  .aside {
    position: relative;
    margin-top: 10px;
    padding: 20px;
    background-color: theme(panel-background-color);
    border-radius: 2px;

    &:first-child {
      margin-top: 0;
    }
  }
}
