@import 'src/styles/base';

@include themify($themes) {
  .footer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
    padding: 20px 0 0;
    font-family: theme(font-primary);
    border-top: 1px solid theme(panel-horizontal-line-color);
  }

  .packageName {
    padding: 4px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-text);
    line-height: 1;
  }

  .totalPrice {
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-title);

    b {
      font-weight: theme(font-weight-light);
    }
  }
}
