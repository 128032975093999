@import 'src/styles/base';

@include themify($themes) {
  .buttonWrap {
    position: absolute;
    top: 0;
    width: 90px;
    height: 100%;
    margin: 0 -90px;
    background: transparent;
    cursor: pointer;
    transition: opacity 0.3s ease-in;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }

    &:global(.slick-disabled) {
      opacity: 0.25;
    }
  }

  .button {
    position: absolute;
    top: 100px;
    display: block;
    width: 30px;
    height: 30px;
    margin: -15px 30px 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.38);
    border: 0;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }

  .buttonArrow {
    color: #fff;
    font-size: 15px;
    line-height: 32px;
    opacity: 0.75;

    .buttonWrap:first-child & {
      padding-right: 3px;
    }

    .buttonWrap:last-child & {
      padding-left: 3px;
    }
  }
}
