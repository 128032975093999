@import 'src/styles/base';

@include themify($themes) {
  .root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: $gap;
    padding-bottom: $gap-3;
  }
}
