/* stylelint-disable order/properties-order, indentation, property-no-vendor-prefix, value-no-vendor-prefix */
@import 'src/styles/base';

@include themify($themes) {
  :global {
    .react-datepicker__tether-element-attached-top .react-datepicker__triangle,
    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      position: absolute;
      margin-left: -8px;
    }

    .react-datepicker__tether-element-attached-top .react-datepicker__triangle,
    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before,
    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before {
      position: absolute;
      box-sizing: content-box;
      width: 1px;
      height: 0;
      border: 8px solid transparent;
    }

    .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before,
    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before {
      left: -8px;
      z-index: -1;
      border-width: 8px;
      border-bottom-color: theme(date-picker-border-color);
      content: '';
    }

    .react-datepicker__tether-element-attached-top .react-datepicker__triangle {
      top: 0;
      margin-top: -8px;
    }

    .react-datepicker__tether-element-attached-top .react-datepicker__triangle,
    .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
      border-top: 0;
      border-bottom-color: theme(date-picker-heading-background-color);
    }

    .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
      top: -1px;
      border-bottom-color: theme(date-picker-border-color);
    }

    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      bottom: 0;
      margin-bottom: -8px;
    }

    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before {
      border-top-color: theme(date-picker-calendar-background-color);
      border-bottom: 0;
    }

    .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before {
      bottom: -1px;
      border-top-color: theme(date-picker-border-color);
    }

    .react-datepicker {
      position: relative;
      display: inline-block;
      color: theme(date-picker-font-and-border-color);
      font-weight: theme(font-weight-light);
      font-size: 0.8rem;
      font-family: theme(font-primary);
      background-color: theme(date-picker-calendar-background-color);
      border: 1px solid theme(date-picker-border-color);
      border-radius: 0.3rem;
    }

    .react-datepicker__triangle {
      position: absolute;
      left: 50px;
    }

    .react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
      margin-top: -20px;
    }

    .react-datepicker__header {
      position: relative;
      padding-top: 8px;
      text-align: center;
      background-color: theme(date-picker-heading-background-color);
      border-bottom: 1px solid theme(date-picker-border-color);
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll {
      display: inline-block;
      margin: 0 2px;
    }

    .react-datepicker__current-month {
      margin-top: 0;
      color: theme(date-picker-font-and-border-color);
      font-weight: bold;
      font-size: 0.944rem;
    }

    .react-datepicker__navigation {
      position: absolute;
      top: 10px;
      z-index: 1;
      height: 10px;
      width: 0;
      line-height: 1.7rem;
      text-align: center;
      border: 0.45rem solid transparent;
      cursor: pointer;
    }

    .react-datepicker__navigation--previous {
      left: 10px;
      border-right-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__navigation--previous:hover {
      border-right-color: theme(date-picker-hover-color);
    }

    .react-datepicker__navigation--next {
      right: 10px;
      border-left-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__navigation--next:hover {
      border-left-color: theme(date-picker-hover-color);
    }

    .react-datepicker__navigation--years {
      position: relative;
      top: 0;
      display: block;
      margin-right: auto;
      margin-left: auto;
    }

    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__navigation--years-previous:hover {
      border-top-color: theme(date-picker-hover-color);
    }

    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__navigation--years-upcoming:hover {
      border-bottom-color: theme(date-picker-hover-color);
    }

    .react-datepicker__month-container {
      display: inline;
      float: left;
    }

    .react-datepicker__month {
      margin: 0.4rem;
      text-align: center;
    }

    .react-datepicker__week-number {
      display: inline-block;
      width: 1.7rem;
      margin: 0.166rem;
      color: theme(date-picker-inactive-color);
      line-height: 1.7rem;
      text-align: center;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      display: inline-block;
      width: 1.7rem;
      margin: 0.166rem;
      color: theme(date-picker-font-and-border-color);
      line-height: 1.7rem;
      text-align: center;
    }

    .react-datepicker__day {
      cursor: pointer;
    }

    .react-datepicker__day:hover {
      background-color: theme(date-picker-heading-background-color);
      border-radius: 0.3rem;
    }

    .react-datepicker__day--today {
      font-weight: bold;
    }

    .react-datepicker__day--highlighted {
      color: theme(date-picker-calendar-background-color);
      background-color: theme(date-picker-highlighted-day-color);
      border-radius: 0.3rem;
    }

    .react-datepicker__day--highlighted:hover {
      background-color: theme(date-picker-highlighted-day-hover-color);
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      color: theme(date-picker-calendar-background-color);
      background-color: theme(date-picker-selected-day-color);
      border-radius: 0.3rem;
    }

    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover {
      background-color: theme(date-picker-selected-day-hover-color);
    }

    .react-datepicker__day--keyboard-selected {
      color: theme(date-picker-calendar-background-color);
      background-color: theme(date-picker-selected-day-by-keyboard-color);
      border-radius: 0.3rem;
    }

    .react-datepicker__day--keyboard-selected:hover {
      background-color: theme(date-picker-selected-day-hover-color);
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
      background-color: theme(date-picker-selected-range-color);
    }

    .react-datepicker__month--selecting-range,
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
      color: theme(date-picker-font-and-border-color);
      background-color: theme(date-picker-heading-background-color);
    }

    .react-datepicker__day--disabled {
      color: theme(date-picker-inactive-color);
      cursor: default;
    }

    .react-datepicker__day--disabled:hover {
      background-color: transparent;
    }

    .react-datepicker__input-container {
      position: relative;
      display: block;
      box-shadow: theme(input-field-outter-box-shadow);
    }

    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view {
      border: 1px solid transparent;
      border-radius: 0.3rem;
    }

    .react-datepicker__year-read-view:hover,
    .react-datepicker__month-read-view:hover {
      cursor: pointer;
    }

    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: theme(date-picker-hover-color);
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      position: relative;
      top: 8px;
      float: right;
      margin-left: 20px;
      border-width: 0.45rem;
      border-top-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown {
      position: absolute;
      top: 30px;
      left: 25%;
      width: 50%;
      text-align: center;
      background-color: theme(date-picker-heading-background-color);
      border: 1px solid theme(date-picker-border-color);
      border-radius: 0.3rem;
    }

    .react-datepicker__year-dropdown:hover,
    .react-datepicker__month-dropdown:hover {
      cursor: pointer;
    }

    .react-datepicker__year-dropdown--scrollable,
    .react-datepicker__month-dropdown--scrollable {
      height: 150px;
      overflow-y: scroll;
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option {
      display: block;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      line-height: 20px;
    }

    .react-datepicker__year-option:first-of-type,
    .react-datepicker__month-option:first-of-type {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:last-of-type,
    .react-datepicker__month-option:last-of-type {
      border-bottom-right-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover {
      background-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: theme(date-picker-hover-color);
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: theme(date-picker-hover-color);
    }

    .react-datepicker__year-option--selected,
    .react-datepicker__month-option--selected {
      position: absolute;
      left: 15px;
    }

    .react-datepicker__close-icon {
      display: inline-block;
      height: 0;
      padding: 0;
      vertical-align: middle;
      background-color: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
    }

    .react-datepicker__close-icon::after {
      position: absolute;
      top: 50%;
      right: 7px;
      bottom: 0;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      margin: -8px auto 0;
      padding: 2px;
      color: theme(date-picker-calendar-background-color);
      font-size: 12px;
      line-height: 1;
      text-align: center;
      background-color: theme(date-picker-selected-day-color);
      border-radius: 50%;
      cursor: pointer;
      content: '\00d7';
    }

    .react-datepicker__today-button {
      clear: left;
      padding: 5px 0;
      font-weight: bold;
      text-align: center;
      background: theme(date-picker-heading-background-color);
      border-top: 1px solid theme(date-picker-border-color);
      cursor: pointer;
    }

    .react-datepicker__tether-element {
      z-index: 2147483647;
    }

    .input-styled {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 9px;
      color: theme(date-picker-input-font-color);
      font-size: 14px;
      line-height: 1.29;
      background: theme(date-picker-input-background-color);
      border: 1px solid theme(date-picker-font-and-border-color);
    }

    .datePickerContainer {
      display: flex;
      flex-direction: column;
    }

    .react-datepicker__portal {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2147483647;
      display: flex;
      display: -webkit-flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      background-color: theme(date-picker-overlay-color);
    }

    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day {
      width: 3rem;
      line-height: 3rem;
    }

    @media (max-width: 400px), (max-height: 550px) {
      .react-datepicker__portal .react-datepicker__day-name,
      .react-datepicker__portal .react-datepicker__day {
        width: 2rem;
        line-height: 2rem;
      }
    }

    .react-datepicker__portal .react-datepicker__current-month {
      font-size: 1.44rem;
    }

    .react-datepicker__portal .react-datepicker__navigation {
      border: 0.81rem solid transparent;
    }

    .react-datepicker__portal .react-datepicker__navigation--previous {
      border-right-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: theme(date-picker-hover-color);
    }

    .react-datepicker__portal .react-datepicker__navigation--next {
      border-left-color: theme(date-picker-inactive-color);
    }

    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: theme(date-picker-hover-color);
    }
  }
}
