@import 'src/styles/base';

@include themify($themes) {
  .contacts {
    position: relative;
    padding: theme(contacts-padding-left-right) theme(contacts-padding-top-bottom);
    color: theme(color-primary);
    font-weight: theme(contacts-font-weight);
    font-family: theme(font-primary);
    background-color: theme(color-page-background);

    a {
      text-decoration: none;
    }
  }

  .contactsTitle {
    margin: 0;
    padding-bottom: 8px;
    font-weight: theme(heading-1-font-weigth);
    font-size: theme(heading-1-font-size);
    text-transform: uppercase;
    border-bottom: theme(contacts-horizontal-line-width) solid theme(contact-border-color);
  }

  .contactsInfo {
    display: flex;
    padding-top: theme(padding-1);
    padding-bottom: theme(padding-1);
  }

  .hotelInfo {
    display: flex;
    justify-content: space-between;
    width: 50%;
    font-size: theme(font-size-text);

    h4 {
      margin: 0;
      padding-bottom: theme(heading-2-padding-bottom);
      font-weight: theme(heading-2-font-weigth);
      font-size: theme(heading-2-font-size);
    }

    p {
      margin: 0;
      line-height: theme(contact-line-height);
    }

    &.au {
      padding-right: 90px;
    }

    &.sia {
      padding-left: 25px;
      border-left: theme(contacts-vertical-line-width) solid theme(contact-border-color);
    }
  }

  .contactsLinkMore {
    padding-top: theme(padding-1);
    padding-bottom: theme(padding-1);
    font-size: theme(font-size-text);
    text-align: center;
    border-top: theme(contacts-horizontal-line-width) solid theme(contact-border-color);
    border-bottom: theme(contacts-horizontal-line-width) solid theme(contact-border-color);

    a {
      color: theme(color-link);
      text-transform: theme(contacts-link-text-transform);

      &::after {
        position: relative;
        top: 0;
        left: 0;
        padding-left: 0.1em;
        content: theme(link-arrow-right);
      }

      &:hover {
        color: theme(color-link-hover);
      }
    }
  }

  .contactsAcceptedCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    font-weight: 300;
    font-size: theme(font-size-text);

    span {
      display: inline-block;
      margin-left: theme(contacts-cards-padding);
      list-style: none;
      background-position: center center;
    }

    .cardsVisa {
      width: 53px;
      height: 20px;
      background-size: 53px 20px;
    }

    .cardsMastercard {
      width: 38px;
      height: 27px;
      background-size: 38px 27px;
    }
  }
}
