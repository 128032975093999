@import 'src/styles/base';

@include themify($themes) {
  .direction {
    margin-top: 30px;
  }

  .directionTitle {
    margin-bottom: 15px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    text-align: left;
  }

  .directionName {
    margin-right: 6px;
    font-weight: 500;
  }

  .directionTransports {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: -20px;
  }

  .transportField {
    flex-grow: 1;
    max-width: 131px;
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .title {
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-text);
    line-height: 1.29;
  }

  .input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 11px 10px 9px;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1;
    background-color: theme(panel-background-color);
    border: 1px solid theme(input-field-border-color);
    border-radius: 2px;
  }

  .field {
    width: 140px;
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .comment {
    margin-top: 30px;
  }

  .commentInput {
    display: block;
    width: 100%;
    height: 40px;
    padding: 11px 12px 9px;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1;
    background-color: theme(panel-background-color);
    border: 1px solid theme(input-field-border-color);
    border-radius: 2px;
    box-shadow: theme(input-field-inner-box-shadow);
    appearance: none;

    &::placeholder {
      color: theme(input-field-placeholder-color);
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .directionTransports {
      flex-wrap: nowrap;
      margin-bottom: 0;
    }

    .transportField {
      margin-bottom: 0;
    }
  }
}
