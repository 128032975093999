@import 'src/styles/base';

@include themify($themes) {
  .menu {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 2000;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px 60px 10px 10px;
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    background-color: theme(mobile-menu-background-color);
    transition: left 0.2s;
    will-change: transition;

    &__opened {
      left: 0;
    }
  }

  .closeSpace {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 50px;
    text-align: center;
    background-color: theme(button-outline-background-color);
    border: 1px solid theme(mobile-menu-close-button-border);
    cursor: pointer;

    &:hover {
      background-color: theme(button-outline-background-hover-color);
    }
  }

  .close {
    padding: 20px 10px;
    color: theme(button-outline-color);
    vertical-align: top;
  }
}
