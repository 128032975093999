@import 'src/styles/base';

@include themify($themes) {
  .dates {
    display: flex;
    justify-content: space-between;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-family: theme(font-primary);
  }

  .date {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .dateTitle {
    width: 100%;
    padding: 16px 0 10px;
    font-size: theme(font-size-text);
  }

  .dateValue {
    font-size: 56px;
    line-height: 1;
  }

  .dateDescription {
    padding-left: 12px;
    font-size: theme(font-size-text);
    line-height: 1;
  }
}
