@import 'src/styles/base';

@include themify($themes) {
  .languageSelector {
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 10px;
      color: theme(color-primary-invert);
      font-size: 13px;
      font-family: FontAwesome;
      transform: translateY(-50%);
      content: '\f107';
      pointer-events: none;
    }
  }

  .select {
    width: 100%;
    height: 40px;
    padding: 10px;
    color: theme(color-primary-invert);
    font-weight: 500;
    font-size: 14px;
    font-family: theme(font-primary);
    text-align: center;
    text-transform: uppercase;
    text-indent: 1px;
    text-overflow: '';
    background: theme(color-primary);
    border: 0;
    border-radius: 0;
    outline: none;
    cursor: pointer;
    appearance: none;

    option {
      display: block;
      color: theme(color-primary-invert);
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
    }

    &:-moz-focusring {
      color: theme(color-primary-invert);
      text-shadow: 0 0 0 theme(color-shadow);
    }

    &::after {
      content: 'f107';
    }

    &::-ms-expand {
      display: none;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .languageSelector {
      &::after {
        right: 2px;
      }
    }

    .select {
      height: auto;
      padding: 10px 18px 10px 4px;
    }
  }
}
