@import 'src/styles/base';

@include themify($themes) {
  .hiddenContentAccordion {
    display: none;
  }

  .accordionTab {
    height: auto;
    margin: 0 0 2px 2px;
    padding: 15px;
    background: $color-black;
  }

  .accordionTabTitle {
    color: $color-white;
    font-weight: 500;
    font-size: 18px;
  }
}
