@import 'src/styles/base';

@include themify($themes) {
  .container {
    width: 40px;
    height: 40px;
  }

  .container {
    position: relative;
    width: auto;
    min-width: 132px;
    height: auto;
    transition: width 0.2s ease;
  }

  /* stylelint-disable */
  :global #sso_container #SSO_Injectable {
    .sso-app-container,
    .sso-auth-btn-wrapper {
      position: relative;
    }

    .sso-popup-enter {
      animation: popup 0.01s ease;
    }

    .sso-shared {
      left: 15px;
    }

    .sso-auth-btn-wrapper .sso-auth-btn {
      background-color: theme(color-primary);
    }

    .sso-mobile {
      .sso-auth-btn-wrapper .sso-auth-btn {
        background-color: theme(sso-background-color);
      }

      .sso-btn-content span {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
  /* stylelint-enable */
}
