@import 'src/styles/base';

@include themify($themes) {
  .text {
    color: theme(color-primary);
    font-weight: theme(font-weigh-ligher);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 1.72;
    text-align: left;
  }
}
