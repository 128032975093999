@import 'src/styles/base';

@include themify($themes) {
  .close {
    width: 25px;
    height: 25px;
    background-color: $color-white;
    border: 0;
    opacity: 0.3;
  }

  .close:hover {
    opacity: 1;
  }
}
