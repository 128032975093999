@import 'src/styles/base';

@include themify($themes) {
  .toolbar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    height: 70px;
    margin: 0 auto;
    padding: 0;
    font-family: theme(font-primary);
    background-color: theme(sso-background-color);
    text-align: center;

    &__desktop {
      @extend .toolbar;
      padding: 15px;
    }
  }

  .toolbarLeft {
    margin-right: 5px;
    color: theme(color-primary-invert);
    font-size: theme(font-size-title);
    line-height: 40px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .toolbarRight {
    display: flex;
    flex-shrink: 0;
  }

  .toolbarMobile {
    position: relative;
    display: block;
    width: 100%;
  }

  .toolbarMobileText {
    color: theme(color-primary-invert);
    font-size: theme(font-size-subtitle);
    padding: 5px 0 1px;
  }

  .toolbarButtons {
    display: flex;
    flex-shrink: 0;

    button {
      flex-shrink: 0;
      margin-left: 20px;
    }
  }

  .toolbarTotal {
    margin-right: 15px;
    color: theme(color-primary-invert);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-title);
    line-height: 40px;
    white-space: nowrap;

    span {
      font-weight: theme(font-weight-light);
      font-size: 24px;
      line-height: 24px;
    }
  }

  .toolbarTotalCount {
    font-weight: 700;
  }

  .toolBarButton {
    width: auto;
  }

  .roomsContainer {
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    padding: 30px 10px 0;
    background: theme(panel-subpanel-background-color);
  }

  .rooms {
    width: 100%;
  }

  .roomRow {
    font-size: theme(font-size-title);
    line-height: 1.1;
  }

  .roomColumn {
    padding: 0 0 30px 50px;
    color: theme(color-primary);
    vertical-align: top;

    &:first-child {
      padding-left: 0;
      font-weight: theme(font-weight-normal);
      white-space: nowrap;
    }
  }

  .roomRemoveLink {
    color: theme(color-link);
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: theme(color-link-hover);
    }

    span {
      margin-left: 0.15em;
      font-size: 24px;
    }
  }

  @media (min-width: $mobile-breakpoint) {
    .toolBarButton {
      width: 225px;
    }

    .toolbarTotal {
      padding-right: 30px;
      font-weight: theme(font-weight-light);
    }
  }
}
