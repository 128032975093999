@import 'src/styles/base';

@include themify($themes) {
  .mealsForm {
    padding-top: $padding-mobile;
  }

  .mealsTitle {
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-text);
    text-align: left;
  }

  .mealsPackages {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .mealField {
    flex-grow: 1;
    max-width: 123px;
    margin-top: 15px;
    margin-right: 30px;
  }

  @media (min-width: $desktop-breakpoint) {
    .mealsForm {
      padding-top: $padding-desktop;
    }

    .mealField {
      margin-right: $padding-desktop;
    }
  }
}
