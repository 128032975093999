/* stylelint-disable scss/percent-placeholder-pattern, scss/at-extend-no-missing-placeholder */
@import 'src/styles/base';

@include themify($themes) {
  .asideClubOne {
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
  }

  .logo {
    margin: 10px 0 30px;
  }

  .logoWrapper {
    text-align: left;
  }

  .name {
    margin: 0 0 1em;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    text-align: left;
  }

  .status {
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 14px;
    vertical-align: middle;
  }

  .statusIcon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    vertical-align: middle;
    border-radius: 50%;
  }

  .statusClubOneLevel {
    vertical-align: middle;
  }

  .statusPoints {
    margin-left: 16px;
    font-weight: 400;
    vertical-align: middle;
  }

  .description {
    font-weight: 300;

    b {
      font-weight: 400;
    }
  }

  .progress {
    position: relative;
    margin: 16px -4px 31px;
    padding: 6px 35px;
  }

  .progressTitle {
    margin: 0 0 16px;
    font-weight: 300;
    font-size: 14px;
  }

  .progressLine {
    overflow: hidden;
    background: theme(club-one-progress-color);
    border-radius: 2px;
  }

  .progressLineFilled {
    height: 5px;
    background: theme(club-one-progress-filler-color);
    border-radius: 2px 0 0 2px;
  }

  %progressCard {
    position: absolute;
    top: 0;
    padding: 0 4px;
    background: theme(panel-background-color);

    .progressName {
      position: absolute;
      top: 100%;
      left: 4px;
      padding-top: 4px;
      font-weight: 300;
      font-size: 12px;
    }
  }

  .progressBronze {
    @extend %progressCard;
    left: 0;
    text-align: left;
  }

  .progressSilver {
    @extend %progressCard;
    left: 50%;
    transform: translateX(-50%);

    .progressName {
      right: 0;
      left: 0;
      text-align: center;
    }
  }

  .progressGold {
    @extend %progressCard;
    right: 0;

    .progressName {
      right: 4px;
      text-align: right;
    }
  }

  .signInDescription {
    display: inline-block;
    width: 100%;
    font-weight: 300;
    font-size: 14px;
  }

  .signUpQuestion {
    @extend .signInDescription;
  }

  .signUpHere {
    @extend .signInDescription;

    width: auto;
    margin-left: 5px;
    color: theme(color-link);
    cursor: pointer;

    &:hover {
      color: theme(color-link-hover);
    }
  }

  .signInBlock {
    margin-bottom: 15px;
    text-align: center;
  }

  .signButton {
    margin: 10px;
  }

  .more {
    margin: 16px 0 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  .moreStatus {
    b {
      font-weight: 400;
    }

    .moreStatusPastNote {
      display: inline;
    }
  }

  .wave {
    position: relative;
    overflow: hidden;
    background: theme(panel-background-color);
  }

  .wave::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: -8px;
    height: 11px;
    background-image: radial-gradient(circle at 10px -10px, transparent 21px, theme(color-page-background) 15px);
    background-repeat: repeat;
    background-size: 21px 20px;
    content: '';
  }

  .wave::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: -8px;
    height: 12px;
    background-image: radial-gradient(circle at 10px 24px, #efefef 18px, transparent 13px);
    background-repeat: repeat;
    background-size: 42px 20px;
    content: '';
  }

  @media (min-width: $mobile-breakpoint) {
    .logoWrapper {
      text-align: center;
    }

    .signInDescription {
      width: 230px;
    }

    .signUpQuestion {
      width: 100%;
    }

    .signUpHere {
      width: auto;
    }
  }
}
