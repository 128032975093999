@import 'src/styles/base';

@include themify($themes) {
  .rsItemAdv {
    display: block;
    align-items: flex-start;
    margin-bottom: $padding-mobile;
    padding: $padding-mobile $padding-mobile 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
  }

  .rsItemDescriptionIcons {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    padding-right: 12px;
    text-align: center;

    &:empty {
      padding-left: 0;
    }
  }

  .rsItemDescriptionIcon {
    margin: 0 18px 0 0;
    font-weight: theme(font-weight-lighter);
    font-size: 12px;
    line-height: 1.5;

    span,
    img {
      display: block;
      width: 23px;
      height: 18px;
      margin: 0 auto 6px;
      line-height: 18px;
      text-align: center;
    }
  }

  .rsItemDescriptionText {
    margin-top: 10px;
    font-weight: theme(font-weight-lighter);
    font-size: 16px;
    line-height: 1.25;
  }

  @media (min-width: $mobile-breakpoint) {
    .rsItemAdv {
      display: flex;
      margin-bottom: $padding-mobile;
      padding: $padding-desktop $padding-desktop 0;
    }

    .rsItemDescriptionIcons {
      justify-content: flex-start;
    }

    .rsItemDescriptionText {
      margin-top: 0;
    }
  }
}
