/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .searchInputIcon {
    position: relative;

    .search {
      position: absolute;
      top: 50%;
      right: 9px;
      z-index: 1;
      display: block;
      width: 21px;
      height: 21px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .searchInput {
    width: 100%;
    min-width: 270px;
    min-height: 40px;
    padding: 10px 32px 10px 7px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    line-height: 1.29;
    background-color: theme(panel-background-color);
    border: solid 1px theme(input-field-border-color);
    border-radius: 2px;
    box-shadow: theme(input-field-inner-box-shadow);

    &::placeholder {
      color: theme(input-field-border-color);
    }
  }

  .filterSelect {
    margin: 0 7px 0 0;

    & > div {
      & > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 50%;
        margin-top: 20px;
      }
    }

    select {
      width: 100%;
    }

    label {
      width: 80px;
      margin-right: 10px;
      text-align: left;
    }
  }

  .dashboardContainer {
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    padding: 30px 23px;
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
    border-radius: 2px;
  }

  .activeBookingHeader {
    padding-left: 1px;
    color: theme(color-primary);
    font-size: theme(font-size-title);
    line-height: 1;
  }

  .inputsContainer {
    display: block;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    input {
      color: theme(color-primary);
      font-family: theme(font-primary);
    }
  }

  .filterContainer {
    margin-top: 10px;

    > div {
      display: inline-block;
    }
  }

  .checkbox {
    display: inline-block;
    margin-right: 21px;
    margin-bottom: 10px;
    color: theme(color-primary);
  }

  @media (min-width: $mobile-breakpoint) {
    .dashboardContainer {
      height: 148px;
    }

    .inputsContainer {
      display: flex;
    }

    .searchInput {
      width: 270px;
    }

    .filterSelect {
      margin: 0 7px 0 0;

      & > div {
        & > div {
          margin-top: 0;
        }
      }

      select {
        width: 154px;
      }

      label {
        width: 108px;
        margin-right: 0;
        text-align: right;
      }
    }

    .checkbox {
      margin-bottom: 0;
    }
  }
}
