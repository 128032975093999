@import 'src/styles/base';

@include themify($themes) {
  .bookerDetailsForm {
    width: 100%;
    padding: $padding-mobile;
    color: theme(color-primary);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 18px;
    background-color: theme(panel-background-color);
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .form {
    margin: 20px 0 0;
    border-top: solid 1px theme(panel-horizontal-line-color);
  }

  .title {
    position: relative;
    top: -2px;
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    line-height: 1;
  }

  .mandatory {
    color: theme(color-error);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;
    text-align: right;
  }

  .formTitle {
    display: block;
    margin: $padding-mobile 0;
    font-size: theme(font-size-title);
    line-height: 1.17;
  }

  @media (min-width: $desktop-breakpoint) {
    .bookerDetailsForm {
      padding: $padding-desktop;
    }

    .formTitle {
      margin: $padding-desktop 0;
    }
  }
}
