@import 'src/styles/base';

@include themify($themes) {
  :global {
    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-touch-callout: none;
      user-select: none;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
      position: relative;
      display: block;
      margin: 0;
      padding: 0;
      overflow: hidden;

      &:focus {
        outline: none;
      }

      &.dragging {
        cursor: pointer;
      }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;

      &::before,
      &::after {
        display: table;
        content: '';
      }

      &::after {
        clear: both;
      }

      .slick-loading & {
        visibility: hidden;
      }
    }

    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;

      [dir='rtl'] & {
        float: right;
      }

      img {
        display: block;
      }

      &.slick-loading img {
        display: none;
      }

      &.dragging img {
        pointer-events: none;
      }
    }

    .slick-arrow.slick-hidden {
      display: none;
    }

    .slick-initialized .slick-slide {
      display: block;
    }

    .slick-loading .slick-slide {
      visibility: hidden;
    }

    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }

  .similar {
    margin-top: 7px;
  }

  .similarSlider,
  .similarSliderFlex {
    padding: 20px 90px;
    background-color: theme(panel-background-color);
  }

  .similarSliderFlex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .room {
    width: 300px;
    padding: 0 12px 15px;
    overflow: hidden;
    text-align: center;
  }

  .roomPreview {
    max-width: 290px;
    height: 200px;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
  }

  .roomTitle {
    margin: 20px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-subtitle);
    font-family: theme(font-primary);
    line-height: 1.13;
  }

  .roomPrice {
    margin: 3px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 1.29;
    text-align: center;
  }
}
