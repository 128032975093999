@import 'src/styles/base';

@include themify($themes) {
  .notification {
    position: relative;
    display: block;
    padding: $gap-3 12% $gap-3;
    font-family: theme(font-primary);
    background-color: theme(notifiaction-background-color);

    &__alert {
      background-color: theme(notification-alert-background-color);
    }

    &__remind {
      background-color: #cff4ff;
    }

    &__error {
      background-color: #ffcfcf;
    }

    &__success {
      background-color: #99ec1a;
    }
  }

  .title {
    display: flex;
    flex: 0 0 250px;
    width: 100%;
    margin: 0 30px 20px 0;
    color: theme(notifiaction-font-color);
    font-weight: theme(font-weight-normal);
    font-size: 22px;
    line-height: 1;
    text-align: left;
  }

  .description {
    color: theme(notifiaction-font-color);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-title);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 20px;
    border-radius: 50%;
    transform: rotate(-45deg);
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: -1px;
      background: theme(notifiaction-font-color);
      content: '';
    }

    &::after {
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      margin: 0 0 0 -1px;
      background: theme(notifiaction-font-color);
    }
  }

  .actionBlock {
    display: flex;
    flex-direction: row;
    margin-top: 8.5px;

    .actionButton {
      margin-right: 10px;
      padding: 10px 20px;
      font-weight: theme(font-weight-normal);
      font-size: theme(font-size-text);
      border: solid 1px theme(notifiaction-font-color);
      border-radius: 2px;
      cursor: pointer;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .notification {
      display: flex;
    }

    .title {
      margin: 0 30px 0 0;
      font-size: 28px;
      text-align: right;
    }
  }
}
