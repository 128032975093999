@import 'src/styles/base';

@include themify($themes) {
  .actionsContainer {
    display: inline-block;
    flex-direction: row;
    align-items: center;

    span {
      float: right;
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
      color: theme(color-link);
      font-size: theme(font-size-text);
      text-transform: none;
      cursor: pointer;

      &:hover {
        color: theme(color-link-hover);
      }
    }
  }

  .changeLinkArrow {
    margin-left: 5px;
  }

  @media (min-width: $mobile-breakpoint) {
    .actionsContainer {
      display: flex;

      span {
        width: auto;
        margin-right: 11%;
        margin-bottom: 16px;
      }
    }
  }
}
