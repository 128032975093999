@import 'src/styles/base';

@include themify($themes) {
  .adultFormContainer {
    width: 100%;
    padding-bottom: $padding-mobile;
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 18px;
    background-color: theme(panel-background-color);
    border-top: solid 1px theme(panel-horizontal-line-color);
  }

  .header {
    color: theme(color-primary);
    font-size: theme(font-size-subtitle);
    line-height: 1.17;
  }

  .adultTitle {
    display: inline-block;
    min-width: 120px;
    padding-right: 10px;
    vertical-align: top;
  }

  .adultTitle,
  .checkbox {
    margin: $padding-mobile 0;
  }

  .checkboxTitle {
    position: relative;
    top: -2px;
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-subtitle);
    line-height: 1;
  }

  @media (min-width: $desktop-breakpoint) {
    .header {
      display: flex;
    }

    .adultTitle,
    .checkbox {
      margin: $padding-desktop 0;
    }
  }
}
