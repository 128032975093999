@import 'src/styles/base';
@import 'src/styles/vendors-extensions/react-datepicker';

@include themify($themes) {
  .datepickerTitle {
    display: block;
    padding: 0 0 5px;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: 14px;
    font-family: theme(font-primary);
    line-height: 1.29;
  }

  .datePickerInputContainer {
    position: relative;

    input {
      font-family: theme(font-primary);
    }
  }

  .datePickerInputIcon {
    position: absolute;
    top: 12px;
    right: 10px;
    pointer-events: none;
  }
}
