/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .dashboardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .dashboardContainerNotLogged {
    justify-content: center;
  }

  .reservationsColumn {
    width: 100%;
    margin: 10px;

    & > * {
      margin-top: 8px;
    }
  }

  .noBookingsMessage {
    height: 24px;
    margin: 24px 0 18px;
    color: theme(color-primary);
    font-weight: theme(font-weigh-normal);
    font-size: theme(font-size-title);
    font-family: theme(font-primary);
    text-align: center;
  }

  .statisticsAndManagement {
    width: 580px;

    & > * {
      margin-top: 8px;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .reservationsColumn {
      width: auto;
      margin-right: 15px;
    }
  }
}
