@import 'src/styles/base';

@include themify($themes) {
  .roomRate {
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
  }

  .rsItemStatus {
    position: absolute;
    top: -1px;
    right: -30px;
    height: 30px;
    padding: 7px 17px 7px 12px;
    color: theme(color-primary-invert);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 18px;
    background-color: theme(room-rate-status-background-color);
  }

  .rsItemPackageData {
    position: relative;
    display: block;
    align-items: center;
    justify-content: space-between;
    margin: 0 $padding-mobile;
    padding: $padding-mobile 0;
    border-top: theme(room-rate-horizontal-line-width) solid theme(panel-horizontal-line-color);
  }

  .packageName {
    width: 230px;
    font-weight: theme(font-weight-normal);
    font-size: 18px;
    line-height: 21px;
  }

  .rsItemPackageCons {
    width: 100%;
    margin: $gap 0 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      margin-top: $gap;
      padding: 0 0 0 $gap-2;
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-text);
      line-height: 1.29;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .rsItemPackageConsIcon {
    position: absolute;
    top: 0;
    left: 0;
    color: theme(color-link);
  }

  .rsItemStatusIcon {
    margin-right: 7px;
    color: theme(color-primary-invert);
    font-size: 17px;
    line-height: 15px;
    vertical-align: top;
  }

  .rsItemPackagePrices {
    position: relative;
    display: block;
    width: 100%;
    margin-top: $gap-2;
    text-align: center;

    button {
      width: 200px;
    }
  }

  .priceTotal {
    display: block;
    font-weight: theme(font-weight-light);
    font-size: 18px;
    line-height: 24px;

    b {
      font-weight: theme(font-weight-light);
      font-size: 24px;
      line-height: 25.2px;
    }
  }

  .rsItemPackagePricesAverage,
  .rsItemPackagePricesRegular {
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 18px;

    b {
      font-weight: theme(font-weight-normal);
    }
  }

  .averagePerNightPriceIcon {
    margin-left: 5px;
  }

  .rsItemPackagePricesButton {
    margin-top: $gap-2;
  }

  .details {
    color: theme(color-link);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: theme(color-link-hover);
    }
  }

  .detailsAndBookingButton {
    font-size: theme(font-size-small-text);
  }

  @media (min-width: $mobile-breakpoint) {
    .rsItemPackageData {
      display: flex;
      margin: 0 $padding-desktop;
      padding: $padding-desktop 0;
    }

    .rsItemPackageCons {
      width: 230px;
      margin-top: 0;
    }

    .rsItemPackagePrices {
      width: auto;
      margin-top: 0;
    }

    .rsItemStatus {
      right: auto;
      left: -30px;
    }
  }
}
