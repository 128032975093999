@import 'src/styles/base';

@include themify($themes) {
  .select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
  }

  .wrapper {
    position: relative;
  }

  .input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 20px 0 14px;
    overflow: hidden;
    color: theme(color-primary);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 17px;
    white-space: nowrap;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    background: theme(panel-secondary-background-color);
    border: 1px solid theme(color-primary);
    border-radius: 0;
    box-shadow: theme(input-field-outter-box-shadow);
    appearance: none;

    &__small {
      height: 31px;
      padding: 0 10px;
    }

    &__rightAlign {
      padding: 0 25px 0 14px;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &::-ms-expand {
      display: none;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: 1px;
    padding: 6px 10px 6px 5px;
    color: theme(color-primary);
    background: transparent;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
