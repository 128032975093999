/* stylelint-disable function-url-quotes */
// =============================================================================
// String Replace
// =============================================================================
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face($name, $file-basename, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $font-url: '../fonts/' + $file-basename;
  $src: null;

  $extmods: (
    eot: '#iefix',
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    eot: 'embedded-opentype',
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($font-url + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-weight: $weight;
    font-family: quote($name);
    font-style: $style;
    src: $src;
    font-display: swap;
  }
}

@include font-face('StagSans', 'StagSans-Light', 300, normal, eot woff);
@include font-face('StagSans', 'StagSans-Book', 400, normal, eot woff);
@include font-face('StagSans', 'StagSans-Medium', 500, normal, eot woff);

// GT Walsheim Pro
@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Regular-Oblique', 400, italic, woff);
@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Medium-Oblique', 500, italic, woff);
@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Bold-Oblique', 700, italic, woff);

@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Regular', 400, normal, woff);
@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Medium', 500, normal, woff);
@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Bold', 700, normal, woff);
