@import 'src/styles/base';

@include themify($themes) {
  .selectContainer {
    width: 100%;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 18px;
    background-color: theme(panel-background-color);

    label {
      display: block;
      padding: 5px 0;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
    }

    select {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 15px;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-small-text);
      font-family: theme(font-primary);
      background-color: theme(panel-background-color);
      border: solid 1px theme(input-field-border-color);
      border-radius: 2px;
      outline: none;
      box-shadow: theme(input-field-outter-box-shadow);
      appearance: none;

      option {
        color: theme(color-primary);
      }
    }

    .select {
      position: relative;

      &::after {
        position: absolute;
        right: 14px;
        bottom: 2px;
        display: block;
        color: theme(color-primary);
        font-size: 14px;
        font-family: FontAwesome;
        transform: translateY(-50%);
        content: '\f107';
        pointer-events: none;
      }
    }
  }

  @media (min-width: $mobile-breakpoint) {
    .selectContainer {
      label {
        flex-shrink: 0;
        width: 120px;
        padding: 10px 10px 10px 0;
      }

      select {
        flex: 1 1 190px;
      }

      .select {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .hasError {
    label {
      margin-bottom: 0;
      color: theme(color-error);
    }

    select {
      margin-bottom: 0;
      border-color: theme(color-error);
    }
  }

  .required {
    &::after {
      color: theme(color-error);
      content: ' *';
    }
  }
}
