@import 'src/styles/base';

@include themify($themes) {
  .balloonContainer {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 2;
    padding: 0 0 20px;
  }

  .balloon {
    position: relative;
    width: 288px;
    padding: 24px;
    text-align: left;
    background: theme(panel-background-color);
    border: 2px solid theme(popup-border-color);
    border-radius: 3px;

    &::before,
    &::after {
      position: absolute;
      top: 100%;
      left: 35px;
      width: 0;
      height: 0;
      margin: 2px 0 0;
      border: 20px solid transparent;
      border-top-color: theme(popup-border-color);
      border-bottom: 0;
      content: '';
    }

    &::after {
      margin: 0;
      border-top-color: theme(panel-background-color);
    }
  }
}
