@import 'src/styles/base';

@include themify($themes) {
  .detailsTitle {
    margin: 0;
    padding: 2px 0 0;
    color: theme(color-primary-invert);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    font-family: theme(font-primary);
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    background-color: theme(sso-background-color);
  }
}
