@import 'src/styles/base';

@include themify($themes) {
  .title {
    margin: 0 0 15px;
    color: #000;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    border-bottom: 2px solid #000;
  }
}
