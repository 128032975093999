@import 'src/styles/base';

@include themify($themes) {
  .container {
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
  }

  .balloonTitle {
    margin: 6px 0 1em;
    font-size: 20px;
    line-height: 1.1;
    text-transform: uppercase;
  }

  .prices {
    margin-bottom: -$gap;
  }

  .pricesLine {
    display: flex;
    justify-content: space-between;
    margin-bottom: $gap-2;
    font-size: 18px;
    line-height: 1.1;
  }

  .pricesStays {
    margin-bottom: 20px;
    border-bottom: 1px solid theme(panel-horizontal-line-color);
  }

  .pricesStaysItem {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 15px;
  }
}
