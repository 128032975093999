@import 'src/styles/base';

@include themify($themes) {
  .noResultsBlock {
    margin: 50px 0 25px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
    text-align: center;
  }

  .noResultsTitle {
    font-weight: theme(font-weight-light);
    font-size: 36px;
  }

  .noResultsLabel {
    font-weight: theme(font-weight-lighter);
    font-size: 18px;
    line-height: (28/18);
  }
}
