@import 'src/styles/base';

@include themify($themes) {
  .beds {
    margin: 7px 0 0;
    padding: 20px;
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
  }

  .bed {
    margin: 30px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .bedDescription {
    margin: 30px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;

    &:first-child {
      margin-top: 0;
    }
  }

  .bedDescriptionTitle {
    margin: 0 0 5px;
    font-weight: theme(font-weight-normal);
  }

  .bedDescriptionText {
    padding: 0 0 10px;
  }

  .counter {
    width: 131px;
    margin: 0 auto;
  }

  .add {
    width: 193px;
    margin: 0 auto;
  }

  @media (min-width: $mobile-breakpoint) {
    .beds {
      display: flex;
      margin: 7px 0 0;
      padding: 27px 30px 32px;
      background-color: theme(panel-background-color);
    }

    .bedsTitle {
      flex-shrink: 0;
      width: 175px;
    }

    .bedsList {
      flex: 1 0;
    }

    .bed {
      display: flex;
      align-items: center;
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

    .bedDescriptionText {
      padding: 0 23px 0 0;
    }

    .add {
      flex-shrink: 0;
    }
  }
}
