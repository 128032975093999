@import 'src/styles/base';

@include themify($themes) {
  .phone {
    display: block;
    width: 100%;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 18px;
    background-color: theme(panel-background-color);

    label {
      display: block;
      padding: 5px 0;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
    }

    .select {
      position: relative;
      display: flex;
      width: 100%;

      input {
        display: block;
        width: 75%;
        height: 40px;
        padding: 0 10px;
        color: theme(color-primary);
        font-weight: theme(font-weight-lighter);
        font-size: theme(font-size-small-text);
        font-family: theme(font-primary);
        background-color: theme(panel-background-color);
        border: solid 1px theme(input-field-border-color);
        border-radius: 2px;
        outline: none;
        box-shadow: theme(input-field-inner-box-shadow);
      }

      input::-webkit-calendar-picker-indicator {
        display: none;
      }

      $country-codes-width: 35%;

      .countryCodes {
        width: $country-codes-width;

        input {
          width: 100%;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          appearance: none;
        }
      }

      .phoneNr {
        width: 100 - $country-codes-width;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: -7px;
        z-index: 1;
        display: block;
        width: $country-codes-width;
        color: theme(color-primary);
        font-size: 14px;
        font-family: FontAwesome;
        text-align: right;
        transform: translateY(-50%);
        content: '\f107';
        pointer-events: none;
      }
    }
  }

  @media (min-width: $mobile-breakpoint) {
    .phone {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      label {
        flex-shrink: 0;
        width: 120px;
        padding: 10px 10px 10px 0;
      }

      .select {
        flex: 1 1 190px;
      }
    }
  }

  .hasError {
    .phone {
      label {
        margin-bottom: 0;
        color: theme(color-error);
      }

      .select {
        input,
        select {
          margin-bottom: 0;
          border-color: theme(color-error);
        }
      }
    }
  }

  .required {
    &::after {
      color: theme(color-error);
      content: ' *';
    }
  }
}
