/* stylelint-disable scss/at-extend-no-missing-placeholder */
@import 'src/styles/base';

@include themify($themes) {
  .checkbox {
    position: relative;
    display: inline-block;
    padding-top: 2px;
    padding-left: 24px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.1;
    vertical-align: top;
    cursor: pointer;
  }

  .checkboxCheck {
    position: absolute;
    left: 0;
    margin-top: -2px;
    color: theme(input-field-border-color);
    font-size: theme(font-size-title);
  }

  .checkboxChecked {
    @extend .checkboxCheck;
    color: theme(color-primary);
  }

  .checkboxDisabled {
    @extend .checkbox;
    opacity: 0.35;
  }

  .hiddenCheckbox {
    display: none;
  }

  .required {
    &::after {
      color: theme(color-error);
      content: ' *';
    }
  }

  .redirectLink {
    color: theme(color-link);
    cursor: pointer;

    &:hover {
      color: theme(color-link-hover);
    }
  }
}
