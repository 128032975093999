@import 'src/styles/base';

@include themify($themes) {
  .roomSelector {
    position: relative;
    margin-bottom: -1px;
    padding: 30px;
    overflow: hidden;
    font-family: theme(font-primary);
    background: theme(panel-subpanel-background-color);
  }

  .roomSelectorTitle {
    margin: 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-subtitle);
    line-height: 1;
  }

  .roomSelectorItems {
    overflow: hidden;
  }

  .roomSelectorCounterRow {
    margin-bottom: 5px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
  }

  .roomSelectorItem {
    display: inline-block;
    vertical-align: top;
  }

  .roomSelectorCounter {
    margin-left: 6px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
  }

  .transitionIn,
  .transitionOut {
    display: block;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out;
  }

  .transitionIn {
    max-height: 0;

    &Active {
      max-height: 200px;
    }
  }

  .transitionOut {
    max-height: 200px;

    &Active {
      max-height: 0;
    }
  }
}
