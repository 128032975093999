/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .selectContainer {
    width: 100%;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    line-height: 18px;
  }

  @media (min-width: $mobile-breakpoint) {
    .selectContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      label {
        flex-shrink: 0;
        width: 120px;
        padding: 10px 10px 10px 0;
      }

      select {
        flex: 1 1 190px;
      }
    }
  }

  .selectContainerInner {
    flex: 1 1 190px;
    box-shadow: theme(input-field-outter-box-shadow);
  }

  .selectContainerArrow {
    font-family: FontAwesome;

    &::before {
      content: '\F107';
    }
  }

  .selectContainerArrowOpen {
    font-family: FontAwesome;

    &::before {
      content: '\F106';
    }
  }

  .selectContainerValue {
    display: flex;
  }

  .selectContainerOption {
    display: flex;
    padding: 0.5em 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .hasError {
    label {
      margin-bottom: 0;
      color: theme(color-error);
    }

    & > div {
      margin-bottom: 0;
      border-color: theme(color-error) !important;
    }
  }

  .required {
    &::after {
      color: theme(color-error);
      content: ' *';
    }
  }

  .controlStyle {
    padding-left: 1rem;
    border-radius: 0;
  }

  .optionComponent {
    padding-left: 1rem;
    border-radius: 0;
    height: 42px;
  }

  .verticalCenter {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .optionLabel {
    position: absolute;
    top: 50%;
    left: 40px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .indicatorSeparatorStyle {
    display: none;
  }
}
