@import 'src/styles/base';

@include themify($themes) {
  .messageContainer {
    padding: 40px;
    color: theme(color-primary);
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
  }

  .messageContainerBody {
    margin-bottom: 32px;
  }

  .messageContainerBodyInfo {
    margin-bottom: 32px;

    h1 {
      margin: 0;
      font-weight: theme(font-weight-light);
      font-size: theme(heading-1-font-size);
      text-align: center;
    }

    div {
      margin: 24px 0 0;
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-subtitle);
    }
  }

  .signUpButtonContainer {
    text-align: center;
  }

  .warning {
    color: theme(color-error);
    font-size: theme(font-size-text);
  }

  @media (min-width: $desktop-breakpoint) {
    .messageContainerBody {
      display: flex;
      justify-content: space-between;
    }

    .messageContainerBodyInfo {
      display: inline-block;
      max-width: 800px;
      margin: 0;

      h1 {
        text-align: left;
      }
    }

    .signUpButtonContainer {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      justify-content: center;
      margin: 0 auto;
    }
  }
}
