@import 'src/styles/base';

@include themify($themes) {
  .root {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 7px 0 0;
    padding: 11px 30px 19px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
    background-color: theme(panel-secondary-background-color);
  }

  .topRow,
  .bottomRow {
    display: flex;
    justify-content: flex-start;
    padding: 15px 0;
  }

  .topCol {
    flex: 0 0 134px;
    width: 133px;
    margin: 0 27px 0 0;
  }

  .bottomCol {
    flex: 0 0 112px;
    width: 113px;
    margin: 0 47px 0 0;
  }
}
