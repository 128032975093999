@import 'src/styles/base';

@include themify($themes) {
  .marker {
    position: absolute;
    top: 0;
    right: -20px;
    padding: 2px 15px 0 20px;
    color: theme(color-primary-invert);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 28px;
    text-transform: uppercase;
    background-color: theme(panel-marker-backround-color);
  }

  .icon {
    margin-left: 0.5em;
  }

  @media (min-width: $desktop-breakpoint) {
    .marker {
      right: -30px;
    }
  }
}
