@import 'src/styles/base';

@include themify($themes) {
  .mobileHeader {
    position: relative;
    z-index: 1;
    height: 120px;
    color: theme(color-primary-invert);
  }

  .ssoBar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
    background-color: theme(sso-background-color);
  }

  .menuBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: theme(color-primary);
  }

  .iconWrap {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
  }

  .icon {
    padding: 10px;
    font-size: 20px;
    line-height: 20px;
  }
}
