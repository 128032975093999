@import 'src/styles/base';

@include themify($themes) {
  .priceTitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: -2px 0 6px;
    padding: 3px 0 7px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    font-family: theme(font-primary);
    line-height: 1;
    text-align: left;
    border-bottom: solid 1px theme(panel-horizontal-line-color);
  }

  .priceTotal {
    margin-bottom: -3px;
    color: theme(color-primary);
    font-size: 36px;
    line-height: 1;
  }

  .priceAverage {
    margin-bottom: -3px;
    color: theme(color-primary);
    font-size: 18px;
    line-height: 1;
  }

  .priceAverageInfo {
    color: theme(color-primary);
  }

  .priceBook {
    padding: 20px 0 0;
  }

  .priceBookButton {
    width: 100%;
  }

  .priceCancellation {
    margin-top: 20px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-text);
    line-height: 1.36;
    text-align: center;
  }

  @media (min-width: $desktop-breakpoint) {
    .priceBook {
      padding: 20px 70px 0;
    }

    .priceBookButton {
      width: 200px;
    }
  }
}
