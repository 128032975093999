@import 'src/styles/base';

@include themify($themes) {
  .button {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    background-color: #ff1d1d;
    border: 0;
    border-radius: 2px;
    cursor: pointer;

    &[disabled] {
      color: #9b9b9b;
      background-color: #e8e8e8;
    }
  }

  .wide {
    width: 100%;
  }
}
