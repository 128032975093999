@import 'src/styles/base';

@include themify($themes) {
  .root {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    color: theme(color-primary);
    font-family: theme(font-primary);

    &:first-child {
      margin-top: 0;
    }
  }

  .childItemNumber {
    flex-shrink: 0;
    width: 20px;
    font-size: theme(font-size-small-text);
  }

  .childrenContainer {
    display: flex;
    flex-direction: column;
  }

  .select {
    flex-grow: 1;

    select {
      text-align-last: center;
    }
  }
}
