@import 'src/styles/base';

@include themify($themes) {
  .reservationInfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 0 10px;
    padding: $padding-mobile;
    color: theme(color-primary);
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
    border-radius: 2px;
  }

  .detailsContainer {
    display: block;
    flex-direction: row;
    margin-bottom: 0;
  }

  .roomInfoContainer {
    display: flex;
    flex-direction: column;
    width: 237px;
    border-top: solid 1px theme(panel-horizontal-line-color);
  }

  .verticalLine {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    border: solid 1px theme(panel-horizontal-line-color);
  }

  .text {
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.72;
    text-align: left;
  }

  .textPeriod {
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;
    text-align: left;
  }

  .contactPerson {
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-title);
    line-height: 1;
    text-align: left;
  }

  .horizontalLine {
    height: 1px;
    border: solid 1px theme(panel-horizontal-line-color);
  }

  .reservationCancelled {
    margin-bottom: 16px;
    color: theme(color-cancelled);
    font-size: theme(font-size-text);
  }

  @media (min-width: $desktop-breakpoint) {
    .reservationInfoContainer {
      padding: $padding-desktop;
    }

    .detailsContainer {
      display: flex;
      margin-bottom: 20px;
    }

    .verticalLine {
      width: 1px;
      height: 136.5px;
      margin: 0 20px 0 19px;
      border: solid 1px theme(panel-horizontal-line-color);
    }

    .roomInfoContainer {
      border: solid 0 theme(panel-horizontal-line-color);
    }
  }
}
