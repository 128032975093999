@import 'src/styles/base';

@include themify($themes) {
  .container {
    display: flex;
    flex-direction: column;
    color: theme(color-primary);
    font-weight: theme(font-weight-light);
    font-family: theme(font-primary);
  }

  .title {
    display: block;
    padding: 0 0 5px;
    font-size: 14px;
  }

  .input {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    color: theme(color-primary);
    font-weight: theme(font-weigh-normal);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    background: theme(panel-secondary-background-color);
    border: 1px solid theme(color-primary);
    box-shadow: theme(input-field-inner-box-shadow);
    appearance: none;
  }
}
