@import 'src/styles/base';

@include themify($themes) {
  .roomSearchContainer {
    margin: 30px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
    background-color: theme(panel-secondary-background-color);
  }

  .resultString {
    margin-bottom: $gap;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.1;
  }

  .sort {
    display: block;
  }

  .sortItemsSort {
    display: flex;
    align-items: center;
  }

  .sortItemsName {
    margin-right: 12px;
    padding-top: 2px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.1;
  }

  .rateSortOrder {
    width: 100%;
  }

  @media (min-width: $mobile-breakpoint) {
    .roomSearchContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .resultString {
      margin: 0;
    }
  }
}
