@import 'src/styles/base';

@include themify($themes) {
  $room-title-width: 90px;

  .root {
    display: block;
    align-items: baseline;
    width: 100%;
    margin-bottom: 0;
    font-family: theme(font-primary);

    > * {
      display: inline-block;
      margin-bottom: 20px;
    }
  }

  .guests {
    width: 100%;
    height: 18px;
    margin-bottom: 20px;
    padding-left: 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;
  }

  .roomType {
    width: 100%;
    height: 21px;
    margin-bottom: 20px;
    color: theme(color-primary);
    font-size: theme(font-size-subtitle);
    line-height: 1.17;
    text-align: left;
  }

  .arrowAndTitle {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    color: theme(color-primary);

    &:hover {
      color: theme(color-primary);
    }
  }

  .title {
    width: $room-title-width;
    height: 22px;
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    line-height: 1.1;

    @media all and (-ms-high-contrast: none) {
      min-width: $room-title-width;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .root {
      display: flex;
      margin-bottom: $gap-2;

      > * {
        margin-bottom: 0;
      }
    }

    .guests {
      padding-left: 56px;
    }

    .roomType {
      text-align: right;
    }
  }
}
