@import 'src/styles/base';

@include themify($themes) {
  .flexContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .serviceDataContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px 5px 5px;
  }

  .buttonContent {
    padding: 5px 10px 5px 5px;
  }

  .serviceDataContentRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .serviceDataContentLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .serviceDataContentDurabilityRow {
    display: flex;
  }

  .serviceDataContentPriceRow {
    display: flex;
  }

  .serviceDataContentDurabilityRowValue {
    font-weight: bold;
  }

  .serviceDataContentPriceRowValue {
    font-weight: bold;
  }

  .serviceDataContentName {
    font-weight: bold;
  }

  .medispaModalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
  }

  .medispaModalContentIframeWrapper {
    height: 100%;
    overflow: scroll;
  }

  .medispaModalContentIframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 4px;
  }

  .accordionTabNestedElement {
    height: auto;
    margin: 0 0 2px 2px;
    padding: 5px;
    background: rgb(198, 198, 198);
  }

  .modal {
    height: 100%;
    background: $color-white;
    border-radius: 4px;
  }

  .modalHeader {
    height: 6%;
    padding: 5px;
  }

  .modalBody {
    height: 94%;
  }

  .modalHeader {
    text-align: right;
    border-bottom: 1px solid #eee;
  }

  .modalTitle {
    margin: 0;
  }

  @media (max-width: 800px) {
    .flexContainer {
      flex-direction: column;
    }
  }
}
