@import 'src/styles/base';

@include themify($themes) {
  .steps {
    background-color: theme(panel-secondary-background-color);
    border-radius: 2px;

    @media (min-width: $mobile-breakpoint) {
      display: flex;
      align-items: stretch;
      margin: 7px 0 0;
    }
  }

  .step {
    position: relative;
    padding: 2px 10px 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 41px;

    &__active {
      color: theme(color-link);
      cursor: pointer;

      &:hover {
        color: theme(color-link-hover);
      }
    }

    &__disabled {
      color: theme(button-outline-disabled-color);
    }

    @media (min-width: $mobile-breakpoint) {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      text-align: center;
    }
  }

  .stepArrow {
    display: none;

    @media (min-width: $mobile-breakpoint) {
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      color: theme(button-outline-disabled-color);
      transform: translateY(-50%);
    }
  }
}
