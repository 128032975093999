@import 'src/styles/base';

@include themify($themes) {
  .notification {
    padding: 28px 12%;
    color: theme(notifiaction-font-color);
    font-weight: theme(font-weigh-normal);
    font-family: theme(font-primary);
    text-align: center;
    background-color: theme(notifiaction-background-color);
    border-radius: 2px;
  }

  .title {
    margin: 0;
    color: theme(notifiaction-font-color);
    font-weight: theme(font-weight-normal);
    font-size: 28px;
    line-height: 1;
  }

  .description {
    margin: 10px 0 0;
    color: theme(notifiaction-font-color);
    font-size: theme(font-size-title);
    line-height: 1;
  }
}
