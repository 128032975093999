@import 'src/styles/base';

@include themify($themes) {
  :global {
    .Select-control {
      padding: 0 0 0 15px;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-small-text);
      background-color: theme(panel-background-color);
      border: solid 1px theme(input-field-border-color) !important;
      border-radius: 2px !important;
    }

    .Select-control > .Select-multi-value-wrapper {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: theme(font-size-small-text);
    }

    .Select-arrow-zone {
      padding-right: 7px;
    }
  }
}
