//
// Colors Definition
// color names are generated with http://chir.ag/projects/name-that-color/
//

$color-abbey: #4d5357;
$color-alto: #d8d8d8;
$color-apple: #629f46;
$color-aquamarine: #6cffdc;
$color-black-haze: #eef1f1;
$color-black: #000;
$color-bright-turquoise: #00ecc9;
$color-caribbean-green: #00bea2;
$color-curious-blue: #2a87d0;
$color-dove-gray: #6e6e6e;
$color-dusty-gray: #9b9b9b;
$color-emerald: #3dcc4a;
$color-french-rose: #f14668;
$color-gallery: #efefef;
$color-gray: #898989;
$color-gumbo: #7799a1;
$color-humming-bird: #cdf8eb;
$color-limeade: #64a600;
$color-matisse-dark: #1d5d90;
$color-matisse: #216ba5;
$color-mercury: #e8e8e8;
$color-midnight: #004152;
$color-new-york-pink: #d97e7e;
$color-pacific-blue: #0098c3;
$color-persian-green: #009d85;
$color-porcelain: #f5f8f7;
$color-seashell: #f1f1f1;
$color-sherpa-blue: #004152;
$color-silver-chalice: #aeaeae;
$color-silver: #ccc;
$color-torch-red: #ff1d1d;
$color-white: #fff;
$color-yellow: #fbff00;
$color-dark-yellow: #e6ac00;
$color-your-pink: #ffc8be;

$font-family-gt-walsheim: 'GTWalsheim', sans-serif;
$font-family-stag-sans: 'StagSans', Arial, sans-serif;

$gap: 10px;
$gap-2: 20px;
$gap-3: 28px;

$themes: (
  default: (
    aside-guests-icon-color: $color-torch-red,
    aside-guests-icon-disabled-color: $color-mercury,
    button-background-color: $color-torch-red,
    button-disabled-background-color: $color-mercury,
    button-disabled-color: $color-dusty-gray,
    button-font-color: $color-white,
    button-hover-background-color: $color-torch-red,
    button-outline-background-color: $color-white,
    button-outline-background-hover-color: $color-white,
    button-outline-color-disabled: $color-dusty-gray,
    button-outline-color: $color-torch-red,
    button-outline-disabled-background-color: $color-mercury,
    button-outline-disabled-color: $color-dusty-gray,
    button-text-transform: none,
    club-one-progress-color: $color-seashell,
    club-one-progress-filler-color: $color-pacific-blue,
    color-cancelled: $color-torch-red,
    color-error: $color-torch-red,
    color-font-contrast: $color-alto,
    color-link-hover: $color-torch-red,
    color-link: $color-torch-red,
    color-page-background: $color-gallery,
    color-primary-invert: $color-white,
    color-primary: $color-black,
    color-shadow: $color-black,
    contact-border-color: $color-black,
    contact-line-height: 1,
    contacts-cards-padding: 25px,
    contacts-font-weight: 400,
    contacts-horizontal-line-width: 2px,
    contacts-link-text-transform: none,
    contacts-padding-left-right: 36px,
    contacts-padding-top-bottom: 36px,
    contacts-vertical-line-width: 2px,
    date-picker-border-color: $color-silver-chalice,
    date-picker-calendar-background-color: $color-white,
    date-picker-font-and-border-color: $color-black,
    date-picker-heading-background-color: $color-black-haze,
    date-picker-highlighted-day-color: $color-emerald,
    date-picker-highlighted-day-hover-color: $color-apple,
    date-picker-hover-color: $color-silver-chalice,
    date-picker-inactive-color: $color-silver,
    date-picker-input-background-color: $color-mercury,
    date-picker-input-font-color: $color-black,
    date-picker-overlay-color: rgba(0, 0, 0, 0.8),
    date-picker-selected-day-by-keyboard-color: $color-curious-blue,
    date-picker-selected-day-color: $color-matisse,
    date-picker-selected-day-hover-color: $color-matisse-dark,
    date-picker-selected-range-color: rgba(33, 107, 165, 0.5),
    filter-select-option-hover: $color-white,
    font-primary: $font-family-stag-sans,
    font-size-small-text: 14px,
    font-size-subtitle: 16px,
    font-size-text: 14px,
    font-size-title: 20px,
    font-weight-light: 400,
    font-weight-lighter: 300,
    font-weight-normal: 500,
    header-subsidiary-font-weigth: 300,
    heading-1-font-size: 21px,
    heading-1-font-weigth: 700,
    heading-2-font-size: 14px,
    heading-2-font-weigth: 700,
    heading-2-padding-bottom: 20px,
    input-field-border-color: $color-dusty-gray,
    input-field-inner-box-shadow: none,
    input-field-outter-box-shadow: none,
    input-field-placeholder-color: $color-dusty-gray,
    link-arrow-right: ' »',
    map-marker-active-color: $color-torch-red,
    map-marker-inactive-color: $color-gray,
    mobile-menu-background-color: $color-mercury,
    mobile-menu-close-button-border: $color-dove-gray,
    notifiaction-background-color: $color-limeade,
    notifiaction-font-color: $color-white,
    notification-alert-background-color: $color-dark-yellow,
    padding-1: 14px,
    panel-background-color: $color-white,
    panel-horizontal-line-color: $color-alto,
    panel-marker-backround-color: $color-apple,
    panel-secondary-background-color: $color-mercury,
    panel-subpanel-background-color: $color-mercury,
    popup-border-color: $color-dusty-gray,
    rate-details-cancellation-terms-background-color: $color-gallery,
    room-rate-horizontal-line-width: 1px,
    room-rate-status-background-color: $color-limeade,
    sso-background-color: $color-abbey
  ),
  seaweb: (
    aside-guests-icon-color: $color-midnight,
    aside-guests-icon-disabled-color: $color-mercury,
    button-background-color: $color-bright-turquoise,
    button-disabled-background-color: $color-mercury,
    button-disabled-color: $color-dusty-gray,
    button-font-color: $color-midnight,
    button-hover-background-color: $color-aquamarine,
    button-outline-background-color: $color-white,
    button-outline-background-hover-color: $color-black-haze,
    button-outline-color-disabled: $color-dusty-gray,
    button-outline-color: $color-midnight,
    button-outline-disabled-background-color: $color-mercury,
    button-outline-disabled-color: $color-dusty-gray,
    button-text-transform: uppercase,
    club-one-progress-color: $color-seashell,
    club-one-progress-filler-color: $color-pacific-blue,
    color-cancelled: $color-new-york-pink,
    color-error: $color-new-york-pink,
    color-font-contrast: $color-white,
    color-link-hover: $color-caribbean-green,
    color-link: $color-persian-green,
    color-page-background: $color-black-haze,
    color-primary-invert: $color-white,
    color-primary: $color-midnight,
    color-shadow: $color-black,
    contact-border-color: $color-gumbo,
    contact-line-height: 1.75,
    contacts-cards-padding: 16px,
    contacts-font-weight: 500,
    contacts-horizontal-line-width: 1px,
    contacts-link-text-transform: uppercase,
    contacts-padding-left-right: 40px,
    contacts-padding-top-bottom: 60px,
    contacts-vertical-line-width: 1px,
    date-picker-border-color: $color-silver-chalice,
    date-picker-calendar-background-color: $color-white,
    date-picker-font-and-border-color: $color-midnight,
    date-picker-heading-background-color: $color-black-haze,
    date-picker-highlighted-day-color: $color-emerald,
    date-picker-highlighted-day-hover-color: $color-apple,
    date-picker-hover-color: $color-silver-chalice,
    date-picker-inactive-color: $color-silver,
    date-picker-input-background-color: $color-white,
    date-picker-input-font-color: $color-midnight,
    date-picker-overlay-color: rgba(0, 0, 0, 0.8),
    date-picker-selected-day-by-keyboard-color: $color-curious-blue,
    date-picker-selected-day-color: $color-matisse,
    date-picker-selected-day-hover-color: $color-matisse-dark,
    date-picker-selected-range-color: rgba(33, 107, 165, 0.5),
    filter-select-option-hover: $color-black-haze,
    font-primary: $font-family-gt-walsheim,
    font-size-small-text: 14px,
    font-size-subtitle: 18px,
    font-size-text: 16px,
    font-size-title: 20px,
    font-weight-light: 400,
    font-weight-lighter: 400,
    font-weight-normal: 500,
    header-subsidiary-font-weigth: 300,
    heading-1-font-size: 32px,
    heading-1-font-weigth: 500,
    heading-2-font-size: 20px,
    heading-2-font-weigth: 500,
    heading-2-padding-bottom: 8px,
    input-field-border-color: $color-dusty-gray,
    input-field-inner-box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.2),
    input-field-outter-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15),
    input-field-placeholder-color: $color-dusty-gray,
    link-arrow-right: ' →',
    map-marker-active-color: $color-caribbean-green,
    map-marker-inactive-color: $color-midnight,
    mobile-menu-background-color: $color-mercury,
    mobile-menu-close-button-border: $color-midnight,
    notifiaction-background-color: $color-humming-bird,
    notifiaction-font-color: $color-sherpa-blue,
    notification-alert-background-color: $color-your-pink,
    padding-1: 24px,
    panel-background-color: $color-white,
    panel-horizontal-line-color: $color-alto,
    panel-marker-backround-color: $color-midnight,
    panel-secondary-background-color: $color-white,
    panel-subpanel-background-color: $color-porcelain,
    popup-border-color: $color-dusty-gray,
    rate-details-cancellation-terms-background-color: $color-gallery,
    room-rate-horizontal-line-width: 2px,
    room-rate-status-background-color: #ff37a2,
    sso-background-color: $color-abbey
  )
);

@mixin themify($attr: null, $themes: $themes) {
  @each $theme, $map in $themes {
    $current-theme: map-get($themes, $theme) !global;

    [data-theme='#{$theme}'] {
      @content;
    }

    $current-theme: null !global;
  }
}

@function theme($query, $theme: $current-theme) {
  @return map-get($theme, $query);
}
