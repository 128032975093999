@import 'src/styles/base';

@include themify($themes) {
  .label {
    display: block;
    margin: 0 0 5px;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: 14px;
    font-family: theme(font-primary);
    line-height: 1.29;
    text-align: left;
  }
}
