@import 'src/styles/base';

@include themify($themes) {
  .buttonOutline {
    display: inline-block;
    width: 197px;
    padding: 2px 5px 0;
    color: theme(button-outline-color);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 36px;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    background-color: theme(button-outline-background-color);
    border: 1px solid theme(button-outline-color);
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      background-color: theme(button-outline-background-hover-color);
    }

    &[disabled] {
      color: theme(button-outline-color-disabled);
      background-color: theme(button-outline-disabled-background-color);
      border-color: transparent;
      cursor: default;
    }

    & + & {
      margin-left: 14px;
    }

    &__wide {
      display: block;
      width: 100%;
    }
  }
}
