@import 'src/styles/base';

@include themify($themes) {
  .layout {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    background-color: theme(color-page-background);
  }

  .content {
    width: 100%;
    margin: 0 auto;
  }
}
