@import 'src/styles/base';

@include themify($themes) {
  .header {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: theme(color-primary);
  }

  .home {
    padding: 0 8px 0 10px;
  }

  .logo {
    display: inline-block;
  }

  .subsidiary {
    display: inline-block;
    padding-top: 2px;
    padding-left: 17px;
    color: theme(color-primary-invert);
    font-weight: theme(header-subsidiary-font-weigth);
    font-size: 11px;
    font-family: theme(font-primary);

    a {
      color: theme(color-primary-invert);
      text-decoration: none;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
  }

  .controlsItem {
    display: flex;
    align-items: center;
    height: 26px;
    padding: 0 18px;
    border-right: 1px solid theme(color-primary-invert);

    &:last-child {
      padding: 0 8px;
      border-right: 0;
    }
  }

  .clubOne {
    margin-right: 15px;
    margin-left: 7px;
    cursor: pointer;
  }
}
