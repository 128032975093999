@import 'src/styles/base/variables';

.overview {
  width: auto;
}

.formItem {
  margin: 7px 7px 0;
}

.asideContainer {
  padding: 7px 10px 0 5px;
}

@media (min-width: $mobile-breakpoint) {
  .overview {
    width: $main-width;
  }
}

// remove arrows near selectors in IE11
@media all and (-ms-high-contrast: none) {
  .overview select::-ms-expand {
    display: none;
  }
}
