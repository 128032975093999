@import 'src/styles/base';

@include themify($themes) {
  .radio {
    position: relative;
    display: inline-block;
    padding-top: 2px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.1;
    vertical-align: top;
    cursor: pointer;
  }
}
