/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .roomListContainer {
    display: block;
    flex-direction: row;
    align-items: stretch;
    margin: -10px 0 15px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
  }

  .roomSearchCol {
    width: 133px;
    margin-left: 40px;
  }

  .roomSearchCol {
    display: flex;
    align-content: flex-end;
  }

  .roomList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
  }

  .roomListItem {
    display: block;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  .roomListItemTitle {
    display: block;
    width: 20%;
    padding: 23px 10px 0 0;
    color: theme(color-primary);
    font-size: 14px;
    line-height: 31px;
    white-space: nowrap;
    text-align: right;
  }

  .roomSearchColSm {
    flex-shrink: 0;
    width: 112px;
  }

  .roomSearchColChildren {
    flex-shrink: 0;
    width: 112px;
    margin-left: 10px;
  }

  .roomSearchColSelects {
    flex-shrink: 0;
    float: right;
    width: 48%;
  }

  .roomListItemCountersRow {
    display: flex;
    margin-bottom: 10px;

    & > div {
      width: 40%;

      & > div {
        & > div {
          max-width: 100%;
        }
      }
    }
  }

  @media (min-width: $mobile-breakpoint) {
    .roomListContainer {
      display: flex;
      margin: 15px 0;
    }

    .roomListItem {
      display: flex;

      &:first-child {
        margin-top: 0;
      }
    }

    .roomListItemCountersRow {
      display: flex;
      margin-bottom: 0;

      & > div {
        width: 112px;

        & > div {
          & > div {
            max-width: 150px;
          }
        }
      }
    }

    .roomSearchColSelects {
      float: left;
      width: 132px;
      margin-top: 0;
    }

    .roomSearchColSm {
      margin-right: 24px;
    }

    .roomListItemTitle {
      width: 102px;
      padding: 23px 10px 0 24px;
      text-align: right;
    }

    .roomSearchColChildren {
      margin: 0 44px 0 40px;
    }
  }
}
