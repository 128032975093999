@import 'src/styles/base';

@include themify($themes) {
  .rsrList {
    margin: 0;
  }

  @media (min-width: $mobile-breakpoint) {
    .rsrList {
      margin: 0 10px;
    }
  }
}
