@import 'src/styles/base';

@include themify($themes) {
  .message {
    padding: 6em 12%;
    color: theme(color-primary);
    font-weight: theme(font-weigh-normal);
    font-size: theme(font-size-subtitle);
    font-family: theme(font-primary);
    text-align: center;
  }

  .icon {
    display: block;
    margin: 0 0 0.25em;
    color: rgba(0, 0, 0, 0.35);
    font-size: 5.5rem;
    text-align: center;

    :global {
      &__circle-o-notch {
        animation: fa-spin 2s infinite linear;
      }

      &__exclamation-circle {
        color: theme(color-error);
      }
    }
  }
}
