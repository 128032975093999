@import 'src/styles/base';

@include themify($themes) {
  .line {
    margin: 20px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 1.05;

    &:first-child {
      margin-top: 0;
    }

    b {
      font-weight: theme(font-weight-light);
    }
  }

  .lineFree {
    float: right;
    color: theme(color-primary);
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-text);
    text-align: left;
  }

  .lineFreeArrow {
    margin-left: 10px;
    color: theme(aside-guests-icon-color);
  }
}
