/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .roomInfoContainer {
    display: flex;
    flex-direction: column;
    width: 237px;
    color: theme(color-primary);
    font-size: theme(font-size-small-text);
  }

  .roomTitle {
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    line-height: 1.17;
    text-align: left;
  }

  .hotelTitle {
    margin: 5px 0;
    font-weight: theme(font-weight-lighter);
    line-height: 1.29;
    text-align: left;
  }

  .text {
    font-weight: theme(font-weight-lighter);
    line-height: 1.72;
    text-align: left;
  }

  .nightsAndGuestsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    & > span {
      margin-top: 5px;
    }
  }

  .bookingNumber {
    margin: 5px 0;
    font-weight: theme(font-weight-lighter);
    line-height: 1.29;
    text-align: left;
  }
}
