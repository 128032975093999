@import 'src/styles/base';

@include themify($themes) {
  .upgrade {
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
  }

  .upgradeItems {
    display: flex;
    justify-content: flex-start;
    padding: 30px 20px;
  }

  .upgradeItem {
    width: 227px;
    margin: 0 10px;
  }

  .upgradeItemPreview {
    position: relative;
    height: 115px;
    overflow: hidden;
    transform: translateZ(0);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      content: '';
    }
  }

  .upgradeItemImage {
    width: 100%;
  }

  .upgradeItemTitle {
    position: absolute;
    top: 8px;
    right: 14px;
    left: 14px;
    z-index: 1;
    color: theme(color-primary-invert);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    line-height: 1.29;
    text-align: center;
  }

  .upgradeBottom {
    margin: 0 14px;
  }

  .upgradePrice {
    margin: 0 0 14px;
    padding: 20px 0 7px;
    font-size: theme(font-size-small-text);
    line-height: 1.29;
    text-align: center;
    border-bottom: solid 1px theme(panel-horizontal-line-color);
  }
}
