/* stylelint-disable scss/at-extend-no-missing-placeholder */
@import 'src/styles/base';

@include themify($themes) {
  .counterContainer {
    display: flex;
    flex-direction: column;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
  }

  .counterTitle {
    display: block;
    padding: 0 0 5px;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }

  .innerContainer,
  .innerContainer_small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    max-width: 150px;
    border: 1px solid theme(color-primary);
    box-shadow: theme(input-field-outter-box-shadow);
  }

  .innerContainer > * {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    padding: 11px 0 9px;
    font-size: 14px;
    line-height: 18px;
  }

  .innerContainer_small > * {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 34px;
    height: 31px;
    padding: 7px 0 4px;
    font-size: 14px;
    line-height: 18px;
  }

  .actionStyle {
    position: relative;
    width: 40px;
    margin: -1px;
    font-size: 24px;
    border-right: 1px solid theme(color-primary);
    border-left: 1px solid theme(color-primary);
    cursor: pointer;
    user-select: none;
  }

  .actionStyle_small {
    @extend .actionStyle;
    width: 34px;
    font-size: 20px;
  }

  .valueContainer {
    margin: -1px;
  }
}
