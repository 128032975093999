@import 'src/styles/base';

@include themify($themes) {
  .root {
    display: flex;
    flex-direction: column;
  }

  .title {
    display: block;
    padding: 0 0 5px 20px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    line-height: 1.29;
  }
}
