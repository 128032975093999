@import 'src/styles/base';

@include themify($themes) {
  .inputContainer {
    width: 100%;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 18px;

    label {
      display: block;
      padding: 5px 0;
      color: theme(color-primary);
    }

    input {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0 15px;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-small-text);
      font-family: theme(font-primary);
      background-color: theme(panel-background-color);
      border: solid 1px theme(input-field-border-color);
      border-radius: 2px;
      outline: none;
      box-shadow: theme(input-field-inner-box-shadow);
    }
  }

  @media (min-width: $mobile-breakpoint) {
    .inputContainer {
      label {
        flex-shrink: 0;
        width: 120px;
        padding: 10px 10px 10px 0;
      }

      input {
        flex: 1 1 190px;
      }
    }

    .inputAndLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  .hasError {
    label {
      margin-bottom: 0;
      color: theme(color-error);
    }

    input {
      margin-bottom: 0;
      border-color: theme(color-error);
    }
  }

  .required {
    &::after {
      color: theme(color-error);
      content: ' *';
    }
  }

  .disabled {
    input {
      background-color: theme(button-disabled-background-color);
    }
  }
}
