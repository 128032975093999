@import 'src/styles/base';

@include themify($themes) {
  .container {
    padding: 10px;
  }

  @media (min-width: $mobile-breakpoint) {
    .container {
      padding: 20px 10px 0 5px;
    }
  }
}
