@import 'src/styles/base';

@include themify($themes) {
  .root {
    width: 100%;
    padding: $padding-mobile;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
  }

  .description {
    min-width: 33%;
  }

  .descriptionTitle {
    margin: 0 0 5px;
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    line-height: 1.1;
    text-transform: uppercase;
  }

  .descriptionSubTitle {
    margin: 0 0 22px;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;
    text-transform: uppercase;
  }

  .descriptionText {
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.43;
  }

  .params {
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;
  }

  .param {
    padding: $gap-2 0 0;
  }

  .paramTitle {
    margin-bottom: 5px;
    font-weight: theme(font-weight-normal);
  }

  .paramsColumn {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .paramItem {
      width: 33%;

      @media (max-width: $small-mobile-breakpoint) {
        width: 50%;
      }
    }
  }

  .paramItem {
    margin: 9px 3px 0 0;
  }

  @media (min-width: $desktop-breakpoint) {
    .root {
      display: flex;
      margin-top: 7px;
      padding: $padding-desktop 25px $padding-desktop;
    }

    .description {
      width: 343px;
      padding: 4px 10px 22px 0;
      overflow: hidden;
      border-right: 2px solid theme(panel-horizontal-line-color);
    }

    .params {
      flex-grow: 1;
      margin-left: -2px;
      padding-left: 12px;
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-text);
      line-height: 1.29;
      border-left: 2px solid theme(panel-horizontal-line-color);
    }

    .param {
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
