@import 'src/styles/base';

@include themify($themes) {
  .medispa {
    margin: 7px 7px 0;
    padding: 20px;
    background: #fff;
  }

  .medispaDescription {
    margin: 0 0 12px;
    color: #151515;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.1;
  }

  .medispaList {
    margin-bottom: -30px;
  }

  .medispaItem {
    padding: 18px 0 30px;
    border-top: 2px solid #cfcfcf;

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }

  .button {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }

  .title {
    margin: 0 0 1em;
    color: #000;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
  }

  .description {
    color: #000;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.29;

    b {
      font-weight: 400;
    }
  }

  .moreInfo {
    margin: 40px 0 0;
  }

  .dl {
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;

    dt {
      width: 25%;
      font-weight: 500;
      text-align: right;
    }

    dd {
      width: 75%;
      font-weight: 300;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .medispa {
      padding: 30px;
    }

    .medispaItem {
      border-top-width: 1px;
    }

    .dl {
      justify-content: flex-start;

      dt {
        flex: 0 0 95px;
        width: 95px;
        font-weight: 500;
        text-align: right;
      }

      dd {
        width: auto;
        font-weight: 300;
      }
    }

    .button {
      text-align: left;
    }
  }
}
