@import 'src/styles/base';

@include themify($themes) {
  .root {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 20px;
    color: theme(color-primary);
  }

  .text {
    display: block;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.72;
    text-align: left;
  }

  .roomTypePrice {
    font-size: theme(font-size-text);
    line-height: 1.29;
    text-align: left;
  }

  .totalPriceContainer {
    display: block;
    flex-direction: row;
    justify-content: space-between;

    :not(:first-child) {
      margin-top: 30px;
    }
  }

  .totalPriceNumber {
    font-weight: theme(font-weight-lighter);
    font-size: 28px;
    line-height: 0.64;
    text-align: left;
  }

  @media (min-width: $mobile-breakpoint) {
    .totalPriceContainer {
      display: flex;

      :not(:first-child) {
        margin-top: 0;
      }
    }

    .reservationPriceContainer {
      padding-top: 30px;
    }
  }
}
