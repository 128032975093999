@import 'src/styles/base';

@include themify($themes) {
  .errorMsg {
    position: absolute;
    color: theme(color-error);
  }

  .labelText {
    font-size: theme(font-size-small-text);
  }

  .selectContainer {
    flex: 5;
  }

  .styledSelect {
    display: flex;
    flex: 5;
    align-items: center;

    select {
      flex: 1;
      font-size: theme(font-size-small-text);
      box-shadow: theme(input-field-outter-box-shadow);
      appearance: none;
    }

    &::after {
      position: relative;
      right: 20%;
      color: theme(color-primary);
      font-size: theme(font-size-text);
      font-family: FontAwesome;
      content: '\f107';
      pointer-events: none;
    }
  }

  .expDateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 191px;
  }

  .expDateContainerSeparator {
    flex: 1;
  }

  .hasError {
    color: theme(color-error);

    select {
      margin-bottom: 0;
      border-color: theme(color-error);
    }
  }

  .required {
    &::after {
      color: theme(color-error);
      content: ' *';
    }
  }
}
