@import 'src/styles/base';

@include themify($themes) {
  .formLine {
    margin: $gap-2 0 0;
    padding-bottom: $gap-2;
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-text);
  }

  @media (min-width: $desktop-breakpoint) {
    .specialRequestForm {
      padding: 30px;
    }
  }
}
