@import 'src/styles/base';

@include themify($themes) {
  .rateDetails {
    position: relative;
    padding: 15px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);

    h2,
    h3 {
      margin: 0;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 16px;
      text-transform: uppercase;
    }

    h4 {
      font-size: theme(font-size-text);
    }

    h2,
    h3,
    h4 {
      font-weight: theme(font-weight-normal);
    }
  }

  .closeLink {
    position: absolute;
    top: $gap;
    right: $gap;
    font-size: 24px;
    cursor: pointer;
  }

  .cancellationTerms {
    margin-top: 40px;
    background: theme(rate-details-cancellation-terms-background-color);
    outline: $gap solid theme(rate-details-cancellation-terms-background-color);
  }

  .rateIncludes {
    padding-left: 0;
    list-style: none;
  }

  .additionalInfo {
    font-weight: theme(font-weight-lighter);
  }

  @media (min-width: $mobile-breakpoint) {
    .rateDetails {
      h2 {
        font-size: 36px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 18px;
      }

      padding: 30px 45px;
      font-size: 16px;
    }

    .closeLink {
      top: 30px;
      right: 45px;
      font-size: 30px;
    }

    .cancellationTerms {
      margin-top: 60px;
      outline-width: 20px;
    }
  }
}
