@import 'src/styles/base';

@include themify($themes) {
  .title {
    margin: 0 0 15px;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    font-family: theme(font-primary);
    line-height: 1;
  }
}
