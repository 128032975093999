@import 'src/styles/base';

@include themify($themes) {
  .datepickerTitle {
    display: block;
    padding: 0 0 5px;
    color: theme(color-primary);
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 1.29;
  }

  .datePickerInputContainer {
    position: relative;

    input {
      color: theme(color-primary);
      font-weight: theme(font-weigh-normal);
      font-size: theme(font-size-small-text);
      font-family: theme(font-primary);
    }
  }

  .datePickerInputIcon {
    position: absolute;
    top: 12px;
    right: 10px;
    pointer-events: none;
  }
}
