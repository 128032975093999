@import 'src/styles/base';

@include themify($themes) {
  .layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: $desktop-container-width;
    min-height: 100vh;
    background-color: theme(color-page-background);
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: $desktop-container-width;
    margin: 0 auto;
  }

  .main {
    flex-grow: 1;
    width: $main-width;
  }

  .aside {
    display: flex;
    flex: 0 0 $aside-width;
    flex-direction: column;
    align-items: stretch;
    width: $aside-width;
  }
}
