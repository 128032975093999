@import 'src/styles/base';

@include themify($themes) {
  .errorMessage {
    margin-left: 0;
    color: theme(color-error);
    font-weight: theme(font-weight-lighter);
    font-size: 12px;
    font-family: theme(font-primary);
    line-height: 1.5;
  }

  @media (min-width: $desktop-breakpoint) {
    .errorMessage {
      margin-left: 120px;
    }
  }
}
