$desktop-container-width: 1200px;
$main-width: 800px;
$aside-width: 400px;

$padding-mobile: 20px;
$padding-desktop: 30px;

$small-mobile-breakpoint: 376px;
$mobile-breakpoint: 800px;
$desktop-breakpoint: 801px;
