/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .meals {
    margin: 7px 0 0;
    padding: 20px;
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
  }

  .mealsDescription {
    margin: 0 0 12px;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.1;
  }

  .mealsList {
    margin-bottom: -30px;
  }

  .mealItem {
    padding: 18px 0 30px;
    border-top: 2px solid theme(panel-horizontal-line-color);

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }

  .meal {
    position: relative;
    display: block;
    width: 100%;
  }

  .mealImage {
    position: relative;
    margin: 0 -20px;

    & > img {
      width: 100%;
      vertical-align: top;
    }
  }

  .mealDescription {
    position: relative;
    flex: 1 0;
    margin-top: 20px;
    margin-right: 0;
  }

  .mealPrices {
    flex-shrink: 0;
    width: 100%;
    margin-top: 20px;
  }

  .button {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }

  .title {
    margin: 0 0 1em;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    line-height: 1;
  }

  .description {
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;

    b {
      font-weight: theme(font-weight-light);
    }
  }

  .moreInfo {
    margin: 40px 0 0;
  }

  .dl {
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
    overflow: hidden;
    color: theme(color-primary);
    font-size: theme(font-size-small-text);
    line-height: 18px;

    dt {
      width: 25%;
      font-weight: theme(font-weight-normal);
      text-align: right;
    }

    dd {
      width: 75%;
      font-weight: theme(font-weight-lighter);
    }
  }

  .mealMarker {
    position: absolute;
    top: -20px;
    right: -20px;
  }

  @media (min-width: $desktop-breakpoint) {
    .meals {
      padding: 30px;
    }

    .mealItem {
      border-top-width: 1px;
    }

    .meal {
      display: flex;
      align-items: flex-start;
    }

    .mealPrices {
      width: 134px;
      margin-top: 36px;
    }

    .mealMarker {
      top: 0;
      right: -30px;
    }

    .mealMarker + .pricesBlock {
      margin-top: 24px;
    }

    .mealMarker + .pricesBlock {
      margin-top: 24px;
    }

    .mealMarker + .description {
      margin-right: 30px;
    }

    .mealDescription {
      position: static;
      margin-top: 0;
      margin-right: 30px;
    }

    .dl {
      justify-content: flex-start;

      dt {
        flex: 0 0 95px;
        width: 95px;
        font-weight: theme(font-weight-normal);
        text-align: right;
      }

      dd {
        width: auto;
        font-weight: theme(font-weight-lighter);
      }
    }

    .mealImage {
      position: static;
      flex: 0 0 261px;
      margin: 0;

      & > img {
        max-width: 242px;
      }
    }

    .mealForm {
      width: 100%;
      padding-left: 261px;
    }

    .button {
      text-align: left;
    }
  }
}
