/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .roomSearchContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: $gap $gap 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
    background-color: theme(panel-secondary-background-color);
  }

  .roomSearchTop {
    display: block;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: $gap;
  }

  .roomSearchMiddle {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 15px 0;
  }

  .roomSearchBottom {
    display: block;
    align-items: flex-end;
    justify-content: flex-end;

    & > * {
      margin-bottom: 20px;
    }
  }

  .roomSearchColButton {
    width: 100%;
    margin-left: 0;
  }

  .roomSearchColBig {
    width: 50%;
    margin-bottom: $gap;
  }

  .roomSearchCol {
    width: 133px;
    margin-left: 0;
  }

  .roomSearchColSm {
    width: 100%;
  }

  .roomList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
  }

  .dates {
    .roomSearchCol {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      & > div {
        display: block;
        width: 100%;

        &:last-child:not(:first-child) {
          margin-left: $gap;
        }
      }
    }
  }

  .roomItem {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }


  .minimized {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: $gap;
    padding: $gap;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-family: theme(font-primary);
    background-color: theme(panel-secondary-background-color);
  }

  .headerDatesRow {
    display: block;
    margin-top: $gap;
  }

  .minimizedHeader {
    font-size: 18px;
    line-height: 18px;

    b {
      font-weight: theme(font-weight-normal);
    }
  }

  .minimizedHeaderDates {
    margin-left: 0;
    font-weight: theme(font-weight-light);
  }

  .minimizedHeaderNights {
    display: block;
    margin-top: $gap;
    margin-left: 0;
    font-weight: theme(font-weight-light);
  }

  .minimizedRooms {
    font-size: theme(font-size-text);
    line-height: 18px;
  }

  .promocodeLine {
    margin-top: 30px;
    font-weight: theme(font-weight-lighter);
  }

  .roomLine {
    display: flex;
    margin-top: 30px;
    font-weight: theme(font-weight-lighter);
  }

  .lineTermin {
    display: inline-block;
    width: 100px;
    font-weight: theme(font-weight-light);
    vertical-align: top;
  }

  @media (min-width: $mobile-breakpoint) {
    .roomSearchContainer {
      margin-right: $gap;
      margin-bottom: $gap;
      margin-left: $gap;

      padding: $gap 40px 15px;
    }

    .roomSearchColBig {
      width: 194px;
      margin-bottom: 0;
    }

    .roomSearchTop {
      display: flex;
      margin-bottom: 0;
      padding: 15px 0;
    }

    .roomSearchCol {
      margin-left: 40px;
    }

    .dates {
      display: flex;

      .roomSearchCol {
        display: flex;
        width: 133px;
        margin-bottom: 0;

        .datePickerContainer {
          display: flex;
        }
      }
    }

    .roomSearchBottom {
      display: flex;
      padding: 15px 0;

      & > * {
        margin-bottom: 0;
      }
    }

    .roomSearchColSm {
      width: 112px;
      margin-right: 24px;
    }

    .roomSearchColButton {
      width: 198px;
      margin-left: 6px;
    }

    .minimized {
      margin-top: 0;
      padding: 30px 40px;
    }

    .headerDatesRow {
      display: inline-block;
      margin-top: 0;
    }

    .minimizedHeaderDates {
      margin-left: 70px;
    }

    .minimizedHeaderNights {
      display: inline-block;
      margin-top: 0;
      margin-left: 30px;
    }
  }

}
