@import 'src/styles/base';

@include themify($themes) {
  .adultFormContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
    border-top: solid 1px theme(panel-horizontal-line-color);
  }

  .kidsTitle {
    display: block;
    height: 21px;
    margin: 30px 0;
    color: theme(color-primary);
    font-size: theme(font-size-subtitle);
    font-family: theme(font-primary);
    line-height: 1.17;
  }
}
