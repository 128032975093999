@import 'src/styles/base';

@include themify($themes) {
  .marker {
    font-size: 0;
    text-transform: uppercase;
    background-color: theme(color-page-background);
  }

  .logo {
    margin: 5px 0 5px 15px;
    vertical-align: top;
  }

  .value {
    display: inline-block;
    width: 60px;
    padding: 2px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    font-family: theme(font-primary);
    line-height: 28px;
    text-align: center;
    vertical-align: top;
  }
}
