@import 'src/styles/base';

@include themify($themes) {
  .button {
    display: inline-block;
    box-sizing: border-box;
    width: 200px;
    padding: 0 $gap-2;
    color: theme(button-font-color);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 40px;
    text-align: center;
    text-transform: theme(button-text-transform);
    text-decoration: none;
    vertical-align: top;
    background-color: theme(button-background-color);
    border: 0;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      background-color: theme(button-hover-background-color);
    }

    &[disabled] {
      color: theme(button-color-disabled);
      background-color: theme(button-disabled-background-color);
    }
  }

  .wide {
    width: 100%;
  }
}
