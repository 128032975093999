@import 'src/styles/base';

@include themify($themes) {
  .root {
    display: flex;
    flex-direction: column;
    min-height: 82px;
    padding: $padding-mobile $padding-mobile 0;
    background-color: theme(panel-background-color);
    border-radius: 2px;
  }

  .roomContainer > form > *::before {
    position: absolute;
    width: 720px;
    height: 2px;
    border: solid 1px theme(panel-horizontal-line-color);
    content: '';
  }

  @media (min-width: $mobile-breakpoint) {
    .root {
      padding: $padding-desktop $padding-desktop 0;
    }
  }
}
