/* stylelint-disable selector-max-compound-selectors */
@import 'src/styles/base';

@include themify($themes) {
  :global {
    .rdt {
      position: relative;
    }

    .rdt input {
      display: block;
      width: 100%;
      height: 40px;
      padding: 11px 10px 9px;
      color: theme(color-primary);
      font-weight: theme(font-weight-lighter);
      font-size: theme(font-size-small-text);
      font-family: theme(font-primary);
      line-height: 1;
      background-color: theme(panel-background-color);
      border: 1px solid #000;
      border-radius: 2px;
      appearance: none;
    }

    .rdtPicker {
      position: absolute;
      z-index: 99999 !important;
      display: none;
      width: 250px;
      margin-top: 1px;
      padding: 4px;
      color: theme(color-primary);
      font-family: theme(font-primary);
      background: theme(panel-background-color);
      border: 1px solid theme(popup-border-color);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .rdtOpen .rdtPicker {
      display: block;
    }

    .rdtStatic .rdtPicker {
      position: static;
      box-shadow: none;
    }

    .rdtPicker .rdtTimeToggle {
      text-align: center;
    }

    .rdtPicker table {
      width: 100%;
      margin: 0;
    }

    .rdtPicker td,
    .rdtPicker th {
      height: 28px;
      text-align: center;
    }

    .rdtPicker td {
      cursor: pointer;
    }

    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtHour:hover,
    .rdtPicker td.rdtMinute:hover,
    .rdtPicker td.rdtSecond:hover,
    .rdtPicker .rdtTimeToggle:hover {
      background: #eee;
      cursor: pointer;
    }

    .rdtPicker td.rdtOld,
    .rdtPicker td.rdtNew {
      color: #999;
    }

    .rdtPicker td.rdtToday {
      position: relative;
    }

    .rdtPicker td.rdtToday::before {
      position: absolute;
      right: 4px;
      bottom: 4px;
      display: inline-block;
      border-top-color: rgba(0, 0, 0, 0.2);
      border-bottom: 7px solid #428bca;
      border-left: 7px solid transparent;
      content: '';
    }

    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      background-color: #428bca;
    }

    .rdtPicker td.rdtActive.rdtToday::before {
      border-bottom-color: #fff;
    }

    .rdtPicker td.rdtDisabled,
    .rdtPicker td.rdtDisabled:hover {
      color: #999;
      background: none;
      cursor: not-allowed;
    }

    .rdtPicker td span.rdtOld {
      color: #999;
    }

    .rdtPicker td span.rdtDisabled,
    .rdtPicker td span.rdtDisabled:hover {
      color: #999;
      background: none;
      cursor: not-allowed;
    }

    .rdtPicker th {
      border-bottom: 1px solid #f9f9f9;
    }

    .rdtPicker .dow {
      width: 14.2857%;
      border-bottom: 0;
    }

    .rdtPicker th.rdtSwitch {
      width: 100px;
    }

    .rdtPicker th.rdtNext,
    .rdtPicker th.rdtPrev {
      font-size: 21px;
      vertical-align: top;
    }

    .rdtPrev span,
    .rdtNext span {
      display: block;
      -webkit-touch-callout: none;
      user-select: none;
    }

    .rdtPicker th.rdtDisabled,
    .rdtPicker th.rdtDisabled:hover {
      color: #999;
      background: none;
      cursor: not-allowed;
    }

    .rdtPicker thead tr:first-child th {
      cursor: pointer;
    }

    .rdtPicker thead tr:first-child th:hover {
      background: #eee;
    }

    .rdtPicker tfoot {
      border-top: 1px solid #f9f9f9;
    }

    .rdtPicker button {
      background: none;
      border: 0;
      cursor: pointer;
    }

    .rdtPicker button:hover {
      background-color: #eee;
    }

    .rdtPicker thead button {
      width: 100%;
      height: 100%;
    }

    td.rdtMonth,
    td.rdtYear {
      width: 25%;
      height: 50px;
      cursor: pointer;
    }

    td.rdtMonth:hover,
    td.rdtYear:hover {
      background: #eee;
    }

    .rdtCounters {
      display: inline-block;
    }

    .rdtCounters > div {
      float: left;
    }

    .rdtCounter {
      height: 100px;
    }

    .rdtCounter {
      width: 40px;
    }

    .rdtCounterSeparator {
      line-height: 100px;
    }

    .rdtCounter .rdtBtn {
      display: block;
      height: 40%;
      line-height: 40px;
      cursor: pointer;
      user-select: none;
      -webkit-touch-callout: none;
    }

    .rdtCounter .rdtBtn:hover {
      background: #eee;
    }

    .rdtCounter .rdtCount {
      height: 20%;
      font-size: 1.2em;
    }

    .rdtMilli {
      width: 48px;
      padding-left: 8px;
      vertical-align: middle;
    }

    .rdtMilli input {
      width: 100%;
      margin-top: 37px;
      font-size: 1.2em;
    }
  }
}
