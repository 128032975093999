@import 'src/styles/base';

@include themify($themes) {
  .guests {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0 0;
    font-family: theme(font-primary);
  }

  .guest {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 50%;
    margin: 13px 0 0;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    line-height: 20px;
  }

  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    color: theme(aside-guests-icon-disabled-color);
    font-size: 20px;

    path {
      fill: theme(aside-guests-icon-disabled-color);
    }
  }

  .active {
    color: theme(aside-guests-icon-color);

    path {
      fill: theme(aside-guests-icon-color);
    }
  }
}
