@import 'src/styles/base';

@include themify($themes) {
  .textarea {
    display: block;
    width: 100%;
    height: auto;
    min-height: 40px;
    padding: 11px 11px 9px;
    overflow: hidden;
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 1.29;
    background-color: theme(panel-background-color);
    border: solid 1px theme(color-primary);
    border-radius: 2px;
    outline: none;
    resize: none;
  }

  .hasError {
    label {
      color: theme(color-error);
    }

    .textarea {
      border-color: theme(color-error);
    }
  }
}
