@import 'src/styles/base';

@include themify($themes) {
  .specialRequestForm {
    margin: 7px 0 0;
    padding: $gap-2;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 18px;
    background-color: theme(panel-background-color);

    textarea {
      color: theme(color-primary);
      border: 1px solid theme(input-field-border-color);
      box-shadow: theme(input-field-inner-box-shadow);
    }
  }

  .formLine {
    margin: 15px 0 0;
  }

  @media (min-width: $desktop-breakpoint) {
    .specialRequestForm {
      padding: 30px;
    }
  }
}
