@import 'src/styles/base';

@include themify($themes) {
  .prices {
    padding: $gap;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-small-text);
    line-height: 1.29;
    background-color: theme(color-page-background);
    border-radius: 3px;
  }

  .pricesTitle {
    display: block;
    margin-bottom: 12px;
    font-weight: theme(font-weight-normal);
  }

  .price b {
    font-weight: theme(font-weight-normal);
  }

  @media (min-width: $small-mobile-breakpoint) {
    .prices {
      display: flex;
    }

    .pricesTitle {
      width: 25%;
      margin-bottom: 0;
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .prices {
      display: block;
    }

    .pricesTitle {
      width: auto;
      margin-bottom: 12px;
    }
  }
}
