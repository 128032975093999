@import 'src/styles/base';

@include themify($themes) {
  .footer {
    position: relative;
    padding: 18px 18px 16px;
    color: theme(color-primary-invert);
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-text);
    font-family: theme(font-primary);
    line-height: 1.25;
    text-align: center;
    background: theme(color-primary);

    a {
      color: theme(color-primary-invert);
    }
  }

  .linkedText {
    cursor: pointer;
    text-decoration: underline;
  }
}
