@import 'src/styles/base';

@include themify($themes) {
  .transfers {
    margin: 7px 0 0;
    padding: 20px 20px 0;
    font-family: theme(font-primary);
    background-color: theme(panel-background-color);
  }

  .transfersDescription {
    margin: 0 0 12px;
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.1;
  }

  .transferItem {
    padding: 18px 0 20px;
    border-top: 1px solid theme(panel-horizontal-line-color);

    &:first-child {
      border-top: 0;
    }
  }

  .transfer {
    position: relative;
  }

  .transferImage {
    max-width: 28px;
    max-height: 16px;
    margin-right: 8px;
  }

  .transferPrices {
    margin-top: 18px;
  }

  .button {
    margin-top: 20px;
    text-align: center;
  }

  .title {
    margin: 0 0 1em;
    color: theme(color-primary);
    font-weight: theme(font-weight-normal);
    font-size: theme(font-size-title);
    line-height: 1;

    img {
      max-width: 25px;
      max-height: 15px;
      margin: 0 8px 0 0;
      vertical-align: top;
    }
  }

  .description {
    color: theme(color-primary);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.29;

    b {
      font-weight: theme(font-weight-light);
    }
  }

  @media (min-width: $desktop-breakpoint) {
    .transfers {
      padding: 30px;
    }

    .transfer {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    .transferItem {
      padding-bottom: 30px;
    }

    .transferImage {
      flex-shrink: 0;
      width: 261px;
      max-width: none;
      max-height: none;
      margin-right: 0;
      padding-top: 22px;
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    .transferDescription {
      flex-grow: 1;
      margin-right: 30px;
    }

    .transferPrices {
      flex-shrink: 0;
      width: 134px;
      margin-top: 36px;
    }

    .transferForm {
      width: 100%;
      padding-left: 261px;
    }

    .button {
      margin-top: 30px;
      text-align: left;
    }
  }
}
