@import '~normalize.css';
@import 'base';
@import 'fonts';

@include themify($themes) {
  html,
  body {
    background-color: theme(color-page-background);
  }
}

body {
  position: relative;
  width: 100%;
  margin: 0;
  font-weight: 400;
  font-style: normal;
  cursor: default;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:global .clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';
  }

  &::after {
    clear: both;
  }
}

/* Remove scroll on the body when react-modal is open */
:global .ReactModal__Body--open {
  overflow: hidden;
}
