@import 'src/styles/base';

@include themify($themes) {
  .toolbarButton {
    display: inline-block;
    width: 200px;
    margin-left: 0;
    color: theme(button-font-color);
    font-weight: theme(font-weight-light);
    font-size: theme(font-size-title);
    font-family: theme(font-primary);
    line-height: 35px;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    background-color: theme(button-background-color);
    border: 0;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      background-color: theme(button-hover-background-color);
    }

    &__inverted {
      color: theme(button-outline-color);
      background-color: theme(button-outline-background-color);

      &:hover {
        background-color: theme(button-outline-background-hover-color);
      }
    }

    &[disabled] {
      color: theme(button-disabled-color);
      background-color: theme(button-disabled-background-color);
    }
  }

  @media (min-width: $mobile-breakpoint) {
    .toolbarButton {
      margin-left: 20px;
    }
  }
}
