/* stylelint-disable scss/selector-no-redundant-nesting-selector */
@import 'src/styles/base';

@include themify($themes) {
  .root {
    color: theme(color-primary);
    font-family: theme(font-primary);
  }

  .label {
    display: block;
    margin: 0 0 5px;
    font-weight: theme(font-weight-lighter);
    font-size: 14px;
    line-height: 1.1;
    text-align: left;
  }

  .counter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    border: 1px solid theme(color-primary);
    border-radius: 2px;
    box-shadow: theme(input-field-outter-box-shadow);

    & > * {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      padding: 11px 0 9px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .counter_small > * {
    width: 34px;
    height: 31px;
    padding: 7px 0 4px;
  }

  .action {
    position: relative;
    width: 40px;
    margin: -1px;
    font-weight: theme(font-weight-light);
    font-size: 24px;
    text-align: center;
    border-right: 1px solid theme(color-primary);
    border-left: 1px solid theme(color-primary);
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
  }

  .value {
    margin: -1px;
    font-weight: theme(font-weight-light);
  }
}
