@import 'src/styles/base';

@include themify($themes) {
  .rsItem {
    margin: $gap 0 0;
    font-family: theme(font-primary);
    background: theme(panel-background-color);
    border-radius: 2px;

    &:first-child {
      margin-top: 0;
    }
  }

  .rsItemImage {
    position: relative;
    height: 0;
    padding: 0 0 47%;
    overflow: hidden;
    transform: translateZ(0);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(134deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      content: '';
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 60px;
      z-index: 1;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: theme(panel-background-color);
      content: '';
    }
  }

  .rsItemImageImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .rsItemImageTitle {
    position: relative;
    z-index: 5;
    padding: $gap $gap 2px;
    color: theme(panel-background-color);
    font-weight: theme(font-weight-normal);
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
  }

  .rsItemImageDescription {
    position: relative;
    z-index: 5;
    padding: 0 $gap;
    color: theme(panel-background-color);
    font-weight: theme(font-weight-lighter);
    font-size: theme(font-size-text);
    line-height: 1.3;
    text-transform: uppercase;
  }

  @media (min-width: $desktop-breakpoint) {
    .rsItemImage {
      height: 360px;
      padding: 0;
    }

    .rsItemImageTitle {
      padding: 30px 30px 0;
      font-size: 36px;
      line-height: 1;
    }

    .rsItemImageDescription {
      padding: 0 30px;
    }
  }
}
